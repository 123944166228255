import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useForm } from "react-hook-form";
import { LoginSchema } from "../../validationSchema/authSchema";
import { authInstance } from "../../config/axios";
import { toast } from "react-toastify";
import "./login.css";
import UserContext from "../../context/UserContext";
import { useContext } from "react";

const Login = ({ handleModal }) => {
  const {
    setIsCheckEmailVerification,
    setStoreEmailForReverification,
  } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    // setValue,
  } = useForm({ mode: "onBlur", resolver: yupResolver(LoginSchema) });
  const [pass, setPass] = useState("password");
  const [loader, setLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleLogin = async (values) => {
    try {
      setLoader(true);
      const response = await authInstance.post(
        "/login",
        {
          ...values, rememberMe: isChecked
        },
        { withCredentials: true, credentials: "include" }
      );
      const { status } = response;
      if (status === 200) {
        toast.success("Login Successfully!");
        reset();
        handleModal(false);
        setTimeout(() => {
        window.location.reload();
        },2000);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      if (axios.isAxiosError(e) && e.response) {
        if (e.response.status !== 200) {
          
          const msg = e?.response?.data?.message.includes(
            `Cannot read properties of null (reading 'isEmailVerified')`
          )
            ? "User does not exist "
            : e?.response?.data?.message;
            handleModal(false);
          toast.error(msg, { toastId: "login" });
          if (e?.response?.data?.message === "Email is not verified") {
            setStoreEmailForReverification(values.email);
            setIsCheckEmailVerification(true);
            handleModal(false);
          }
        }
      }
    }
  };

  const handlePass = () => {
    setPass(!pass);
  };
  const handleRemember = (e) => {
    setIsChecked(e?.target?.checked);
  }


  return (
    <div className='login'>
      <div className='login-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='login-form'>
                <h1>Log In</h1>
                <p>Welcome, Please login to your account</p>
                <div className='login-box'>
                  <Form onSubmit={handleSubmit(handleLogin)}>
                    <Form.Group controlId='formBasicFirst'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter your email '
                        name='email'
                        autoComplete='off'
                        {...register("email", {
                          required: "Enter Valid Email",
                        })}
                      />
                      {errors.email && (
                        <p className='error-msg'>{errors.email.message}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId='formBasicPassword'>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={pass ? "password" : "text"}
                        placeholder='Enter your password'
                        name='password'
                        autoComplete='off'
                        {...register("password", {
                          required: "Enter Valid Password",
                        })}
                      />
                      <span className='input-icon1' onClick={handlePass}>
                        <i className={pass ? "las la-eye" : "las la-eye-slash"}></i>
                      </span>
                      {errors.password && (
                        <p className='error-msg'>{errors.password.message}</p>
                      )}
                    </Form.Group>
                    <div className='forget-password'>
                      <div className='remember-me'>
                        <input
                          id='wp-comment-cookies-consent'
                          name='wp-comment-cookies-consent'
                          type='checkbox'
                          value='yes'
                          onChange={handleRemember}
                        />

                        <label for='wp-comment-cookies-consent'>
                          Remember me
                        </label>
                      </div>
                      <Button
                        className='forget-link'
                        onClick={() => handleModal("forget")}
                      >
                        Forgot Password?
                      </Button>
                    </div>
                    <div className='login-button'>
                      <Button type='submit' className='l-btn'>
                        {loader ? (
                          <Spinner
                            animation='border'
                            size='sm'
                            className='accept-btn-show-btn'
                          />
                        ) : (
                          "Login"
                        )}
                      </Button>
                    </div>
                    <div className='social-login'>
                      <span>OR</span>
                      <div className='social-btns'>
                        <Button className='twitch-btn'>
                          <i className='fa fa-steam' /> Login with Steam
                        </Button>
                      </div>
                    </div>
                  </Form>
                  <p className='already'>
                    Don't have an account yet?{" "}
                    <Button
                      className='forget-link'
                      onClick={() => handleModal("register")}
                    >
                      Register
                    </Button>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
