import React from "react";
import { Link } from "react-router-dom";
import seedicon from "../../../assets/images/game/seed.svg"

const PreviousResult = () => {

   const item = 3;
  return(
  <div className="previous-result-grid">



      <div className="previous-result-container">
      {[...Array(item)].map((index) => (
          <>
    <div className="previous-result-box result-loss">
        <span>33.54</span>
    </div>
    <div className="previous-result-box result-gain">
         <span>63.48</span>
    </div>
    <div className="previous-result-box result-loss">
        <span>33.54</span>
    </div>
    <div className="previous-result-box result-loss">
        <span>33.54</span>
    </div>
    <div className="previous-result-box result-gain">
         <span>63.48</span>
    </div>
    <div className="previous-result-box result-gain">
         <span>63.48</span>
    </div>
    <div className="previous-result-box result-loss">
        <span>33.54</span>
    </div>
    <div className="previous-result-box result-loss">
        <span>33.54</span>
    </div>
    <div className="previous-result-box result-gain">
         <span>63.48</span>
    </div>
    <div className="previous-result-box result-gain">
         <span>63.48</span>
    </div>
    </>
    ))}
    </div>
 

    <div className="change-seed-btn">
        <Link to={"/"}><img src={seedicon} alt="" /> Change Seeds</Link>
    </div>
  </div>
)};

export default PreviousResult;