import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import './modals.css';

const Modals = ({view, setView}) => {
  const handleViewClose = () => setView(false);

  return (
        <Modal
          className='qr-modal'
          show={view}
          onHide={handleViewClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Tip</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='qr-code-modal'>
              <div className='address-form'>
                <Form>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <div className='ref-input'>
                      <Form.Control type='text' placeholder='' />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Amount</Form.Label>
                    <div className='ref-input'>
                      <Form.Control type='number' placeholder='0.00' />
                      <div className='ref-input-btn '>
                        <Button className='ref-btn-copy'>MAX</Button>
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              </div>
              <div className='send-tip'>
                <Button><span>SEND TIP</span></Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

  );
};

export default Modals;
