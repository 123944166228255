import React from 'react';
import { Link } from 'react-router-dom';
import coming from "../../assets/images/game/coming-soon.png"
import './style.css';

const ComingSoon = () => {
  return (
      <div className='coming-soon-page'>
        <div className='container'>
          <div className='coming-soon-box'>
            <img src={coming} alt="" />
            <Link to="/">Home</Link>
          </div>
        </div>
      </div>
  );
};

export default ComingSoon;
