import React from "react";
import VerifyLayout from "./verify";
import { Button } from "react-bootstrap";




const Overview = () => {

    return (
        <VerifyLayout>
                <div className="verify-content">

                    <h2>HOW CAN I KNOW THAT THE GAME IS FAIR?</h2>

                    <p>The game results have been generated BEFORE you even place your bet, and most importantly, we can prove it.</p>

                    <p>Before each round, we actually give you the round result in a hashed format</p>

                    <p>Before the round, we give you the hash of the secret seed which the round result is based on. After the round ends, we publish the secret seed, which you can then compare to the hash to make sure that they match. With this system we can prove that the results were fair and pre-generated.</p>

                    <Button><span>Fair Play</span></Button>
                </div>

        </VerifyLayout>

    );
};

export default Overview;