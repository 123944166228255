import React, {useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./otpVerify.css";
import { Spinner } from "react-bootstrap";


const VerifyOtp = ({ handleModal }) => {
    const [otpValue, setOtpValue] = useState('');
    const [loader] = useState(false)
    return (
        <div className="signup">
            <div className="register-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="register-form">
                                <h1>Verify OTP</h1>
                                <p>Welcome, Please enter your OTP to verify your account.</p>
                                <div className="rigister-tab">
                                    <Form>
                                       <Form.Group controlId="formBasicFirst">
                                            <Form.Label>Verify OTP</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter OTP"
                                                name="firstName"
                                                value={otpValue}
                                                // autoComplete="off"
                                                onChange={(e) => { setOtpValue(e.target.value) }}
                                                required
                                            />
                                        </Form.Group>
                                        <div className="text-center">
                                            <Button className="s-btn" type="submit" disabled={loader} >
                                                {loader ? <Spinner animation="border" size="sm"/> : "Verify OTP"}
                                            </Button>
                                        </div>
                                    </Form>
                                    <p className="already">Already have an account? <Button className="forget-link" onClick={() => handleModal("login")}>
                                        Login</Button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyOtp;
