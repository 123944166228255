import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import Slider from "react-slick";
import slider1 from "../../assets/images/home/slide1.jpg";
import slider2 from "../../assets/images/home/slide2.jpg";
import slider3 from "../../assets/images/home/slide3.jpg";
// import slider4 from "../../assets/images/home/banner4.jpg";
// import slider5 from "../../assets/images/home/banner5.jpg";
import rubyrollicon from "../../assets/images/home/rubyroll-icon.svg";
import casinoicon from "../../assets/images/home/casino-icon.svg";
import slots from "../../assets/images/home/slot.jpg";
import limbo from "../../assets/images/home/limbo.jpg";
// import crash from "../../assets/images/home/crash.jpg";
import plinko from "../../assets/images/home/plinko.jpg";
import livegame from "../../assets/images/home/live-games.svg";
import slot from "../../assets/images/home/slot.svg";
import { Table } from "react-bootstrap";
// import coin from "../../assets/images/header/gem.png";
import { Tabs, Tab } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getBetHistoryAllData, getMyBetAllData } from "../../utils/Api";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <Layout>
      <Helmet>
        <body className="homep-page" />
      </Helmet>
      <div className="home-page">
        <HomeSlider />
        <RubyRollOriginals />
        <AllHistory />
        <Casino />
      </div>
    </Layout>
  );
};

export default Home;

// const HomeBanner = () => {
// return(
//   <div className="home-banner">
//       <div className="home-banner-content">
//         <h2>DEPOSIT $50 AND PLAY WITH $100</h2>
//         <Button className="l-btn">Deposit</Button>
//       </div>
//   </div>
// )

// }

const HomeSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="home-slider">
      <div className="container">
        <div className="home-slider-content">
          <Slider {...settings}>
            <div className="home-slider-box">
              <div className="home-slider-space">
                <img src={slider1} alt="" />
              </div>
            </div>
            <div className="home-slider-box">
              <div className="home-slider-space">
                <img src={slider2} alt="" />
              </div>
            </div>
            <div className="home-slider-box">
              <div className="home-slider-space">
                <img src={slider3} alt="" />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

const RubyRollOriginals = () => {
  const navigate = useHistory();
  const handleNav = (url) => {
    navigate.push(url);
  };
  return (
    <div className="home-rubyroll">
      <div className="container">
        <h2>
          <img src={rubyrollicon} alt="" /> Winit Originals
        </h2>
        <div className="home-rubyroll-grid">
          <div className="home-rubyroll-box" onClick={() => handleNav("/slot")}>
            <div className="home-rubyroll-cover">
              <img src={slots} alt="" />
            </div>
            <h4>Slot</h4>
          </div>
          <div className="home-rubyroll-box">
            <div
              className="home-rubyroll-cover"
              onClick={() => handleNav("/limbo")}
            >
              <img src={limbo} alt="" />
            </div>
            <h4>Limbo</h4>
          </div>
          {/* <div className="home-rubyroll-box">
            <div
              className="home-rubyroll-cover"
              onClick={() => handleNav("/crash")}
            >
              <img src={crash} alt="" />
            </div>
            <h4>Crash</h4>
          </div> */}
          <div className="home-rubyroll-box">
            <div
              className="home-rubyroll-cover"
              onClick={() => handleNav("/plinko")}
            >
              <img src={plinko} alt="" />
            </div>
            <h4>Plinko</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const Casino = () => {
  return (
    <div className="home-casino">
      <div className="container">
        <h2>
          <img src={casinoicon} alt="" /> Casino
        </h2>
        <div className="home-casino-grid">
          <div className="home-casino-box">
            <Link to="/livegames">
              <div className="home-casino-cover">
                <img src={livegame} alt="" />
              </div>
            </Link>
          </div>
          <div className="home-casino-box">
            <Link to="/slots">
              <div className="home-casino-cover">
                <img src={slot} alt="" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const AllHistory = () => {
  // defaultActiveKey="allbet"
  const [key, setKey] = useState("allbet");
  const [allBetHistoryData, setAllBetHistoryData] = useState([]);
  const [allMyBetHistoryData, setMyAllBetHistoryData] = useState([]);
  const handleAllBet = async () => {
    if (key === "allbet") {
      const response = await getBetHistoryAllData();
      const { code } = response;
      if (code === 200) {
        setAllBetHistoryData(response?.transactions);
      }
      return;
    }
    if (key === "mybet") {
      const response = await getMyBetAllData();
      const { code } = response;
      if (code === 200) {
        setMyAllBetHistoryData(response?.transactions);
      }
      return;
    }
  };

  const handleSelectKey = async (k) => {
    setKey(k);
  };

  useEffect(() => {
    handleAllBet();
    // eslint-disable-next-line
  }, [key]);
  return (
    <div className="container">
      <div className="dice-tab-grid">
        <div className="dice-tab">
          <Tabs
            id="uncontrolled-tab-example"
            activeKey={key}
            onSelect={(k) => handleSelectKey(k)}
          >
            <Tab eventKey="allbet" title="All Bets">
              <HistoryTable allBetHistoryData={allBetHistoryData} />
            </Tab>
            <Tab eventKey="lucky" title="Lucky Wins">
              <HistoryTable allBetHistoryData={allBetHistoryData} />
            </Tab>
            <Tab eventKey="mybet" title="My Bets">
              <HistoryTable allBetHistoryData={allMyBetHistoryData} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const HistoryTable = ({ allBetHistoryData }) => {
  const getDate = (d) => {
    const date = new Date(d);
    // const day = date.getDate();
    // let month = date.getMonth() + 1;
    // const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // convert to 12-hour format
    // month = getMonthName(month);
    return ` ${hours}:${minutes < 10 ? "0" + minutes : minutes} ${ampm}`;
  };

  return (
    <div className="dice-history">
      {allBetHistoryData && allBetHistoryData?.length > 0 ? (
        <Table striped bordered variant="dark" responsive>
          <thead>
            <tr>
              <th>Game</th>
              <th>User</th>
              <th>Time</th>
              <th>Wagered </th>
              <th>Multiplier</th>
              <th>Profit</th>
            </tr>
          </thead>
          <tbody>
            {allBetHistoryData &&
              allBetHistoryData?.length > 0 &&
              allBetHistoryData.map((ele) => {
                return (
                  <tr>
                    <td>{ele.gameType}</td>
                    <td>{ele.userId.name}</td>
                    <td>{getDate(ele.createdAt)}</td>
                    <td>
                      <div className="transaction-value">
                        {/* <img src={coin} alt="" /> */}${ele.amount}
                      </div>
                    </td>
                    <td>{ele.multiplier}x</td>
                    <td>
                      {ele.profit?.toString()?.startsWith("-") ? (
                        <div className="transaction-value-loss">
                          {` $${ele.profit.toString().split("-").join("")}`}
                        </div>
                      ) : (
                        <div className="transaction-value">
                          ${ele.profit.toFixed(2)}
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ) : (
        <div className="notAvailble-placeholder">Data Not Available</div>
      )}
    </div>
  );
};
