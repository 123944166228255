import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import bitcoin from '../../assets/images/coins/bitcoin.svg';
import ethereum from '../../assets/images/coins/ethereum.svg';
import litecoin from '../../assets/images/coins/litecoin.svg';
import solana from '../../assets/images/coins/solana.svg';
import './modals.css';

const Cashier = ({ handleModal }) => {
  return (
    <>
      <div className='cashier-body'>
        <Tabs
          defaultActiveKey='deposit'
          id='uncontrolled-tab-example'
          className='mb-3'
        >
          <Tab className='deposit-tab' eventKey='deposit' title={<span>DEPOSIT</span>}>
            <DepositTab handleModal={handleModal} />
          </Tab>
          <Tab className='withdraw-tab' eventKey='withdraw' title={<span>WITHDRAW</span>}>
            <WithdrawTab handleModal={handleModal} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default Cashier;

const DepositTab = ({ handleModal }) => {
  return (
    <>
      <div className='deposit-tab-body'>
        <div
          className='bitcoin-box'
          onClick={() => handleModal('bitcoin-deposit')}
        >
          <div className='bitcoin-sub-box'>
            <img src={bitcoin} alt='' />
          </div>
          <h6>Bitcoin</h6>
          <p>Crypto</p>
        </div>
        <div className='bitcoin-box'>
          <div className='bitcoin-sub-box'>
            <img src={ethereum} alt='' />
          </div>
          <h6>Bitcoin</h6>
          <p>Crypto</p>
        </div>
        <div className='bitcoin-box'>
          <div className='bitcoin-sub-box'>
            <img src={litecoin} alt='' />
          </div>
          <h6>Bitcoin</h6>
          <p>Crypto</p>
        </div>
        <div
          className='bitcoin-box'
          onClick={() => handleModal('bitcoin-deposit')}
        >
          <div className='bitcoin-sub-box'>
            <img src={solana} alt='' />
          </div>
          <h6>Bitcoin</h6>
          <p>Crypto</p>
        </div>
      </div>
    </>
  );
};

const WithdrawTab = ({ handleModal }) => {
  return (
    <>
      <div className='deposit-tab-body'>
        <div
          className='bitcoin-box'
          onClick={() => handleModal('bitcoin-withdraw')}
        >
          <div className='bitcoin-sub-box'>
            <img src={bitcoin} alt='' />
          </div>
          <h6>Bitcoin</h6>
          <p>Crypto</p>
        </div>
        <div
          className='bitcoin-box'
          onClick={() => handleModal('bitcoin-withdraw')}
        >
          <div className='bitcoin-sub-box'>
            <img src={ethereum} alt='' />
          </div>
          <h6>Bitcoin</h6>
          <p>Crypto</p>
        </div>
        <div className='bitcoin-box'>
          <div className='bitcoin-sub-box'>
            <img src={litecoin} alt='' />
          </div>
          <h6>Bitcoin</h6>
          <p>Crypto</p>
        </div>
        <div className='bitcoin-box'>
          <div className='bitcoin-sub-box'>
            <img src={solana} alt='' />
          </div>
          <h6>Bitcoin</h6>
          <p>Crypto</p>
        </div>
      </div>
    </>
  );
};
