import React from 'react';
import Profile from './profile';
import fairness from '../../assets/images/profile/fair-red.svg';
import { Form, Button } from 'react-bootstrap';

const Fairness = () => {
  return (
    <Profile>
      <div className='settings-page fairness'>
        <div className='page-heading'>
          <h3>
            <img src={fairness} alt='' /> Fairness
          </h3>
        </div>

        <div className='settings-content'>
          <Form>
            <Form.Group>
              <Form.Label>Change Seed</Form.Label>
              <div className='form-input'>
                <Form.Control
                  type='text'
                  autoComplete='new-password'
                  placeholder='PepeLaugh'
                />
                <div className='form-input-btn'>
                  <Button className='save-btn'><span>SET SEED</span></Button>
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
      </div>
    </Profile>
  );
};

export default Fairness;
