import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import userimg from "../../assets/images/home/user.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import "./profile.css";
import Profile from "./profile";
import UserContext from "../../context/UserContext";
import { changeProfile, changeProfileImage } from "../../utils/Api.js";
import { authInstance } from "../../config/axios";

function ProfilePage() {
  const { user, setUser } = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [imageError, setimageError] = useState(null);
  const [imageLoader, setImageLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const [active,setActive]=useState("");
  const onSelectFile = (e) => {
 
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // It is kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    setimageError(null);
    setActive("upload-active");
  };

  const ProfileSchema = yup.object().shape({
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[a-zA-Z ]*$/,"Please Enter Valid Name"),
      // .min(3, "First name should be atleast three character"),
    lastName: yup
      .string(),
      // .required("Last name is required").matches(/^[A-Za-z]+$/,"Please Enter Valid Name")
      // .min(3, "Last name should be atleast three character"),
    phoneNumber: yup.string().required("Phone number is required").matches(/[0-9]{10,12}/,"Please Enter valid Number").max(12,"Please Enter valid Number"),
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ProfileSchema),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      email: user?.email,
    },
  });

  useEffect(() => {
    reset({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      email: user?.email,
      avatar: user?.avatar,
    });
    setPreview(user?.avatar);
  }, [user, reset]);

  const handleProfileImage = async () => {
    setActive(" ");
    if (selectedFile === undefined ) {
      setimageError("Please select Profile Image");
      return;
    }
    let mbLimit = 10 * 1024 * 1024;
    if (selectedFile?.size > mbLimit) {
      setimageError("Profile Image cannot exceed size of 10MB");
      return;
    }
    setImageLoader(true);
    const formData = new FormData();
    formData.append("avatar", selectedFile);
    const response = await changeProfileImage(formData);
    if (response.code === 200) {
      setImageLoader(false);
      toast.success("Profile uploaded successfully", {
        toastId: "newprofileimg",
      });
      setUser((prev) => ({
        ...prev,
        avatar: response.avatar,
      }));
    } else {
      setImageLoader(false);
      toast.error(response.message);
    }
  };

  const handleProfile = async (values) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("formValues", JSON.stringify(values));
    const response = await changeProfile(formData);
    if (response.code === 200) {
      setLoader(false);
      toast.success("Profile updated successfully", {
        toastId: "newprofilefield",
      });
      setUser((prev) => ({
        ...prev,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        name: `${values.firstName} ${values.lastName}`,
      }));
    } else {
      setLoader(false);
      toast.error(response.message);
    }
  };

  const handleRemoveProfile = async () => {
    try {
      const response = await authInstance.post(`/remove-profile-image`);
      if (response?.data?.code === 200) {
        toast.success("Profile removed successfully", {
          toastId: "newprofilefield",
        });
        setUser(response?.data?.userData);
        setSelectedFile(undefined);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);
  return (
    <Profile>
      <div className='settings-page profile-page'>
        <div className='page-heading'>
          <h3>Profile</h3>
        </div>
        <div className='settings-content' >
          <div className='profile-upload-section'>
            <div className='profile-upload'>
              <img src={preview ? preview : userimg} alt='user' />

              <div className='upload-profile'>
                <label onClick={handleOpen} role='presentation'>
                  <i className='las la-edit'></i>
                </label>
                {open ? (
                  <>
                    <div className='upload-profile-dropdown'>
                      <input
                        type='file'
                        id='imageUpload'
                        name='file'
                        accept='.png, .jpg, .jpeg'
                        onChange={onSelectFile}
                      />
                      <label htmlFor='imageUpload'>
                        <i className='las la-camera'></i>
                        <span>Update Profile</span> 
                      </label>
                      {user?.avatar &&
                        <div className='profile-delete' onClick={handleRemoveProfile}>
                          <label>
                            <i className='lar la-trash-alt'></i>
                            <span>Remove</span>
                          </label>
                        </div>}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <button
              onClick={() => {
                if (!imageLoader) {
                  handleProfileImage();
                }
              }}
            className={`${active}`}
            >
              {" "}
              {imageLoader === true ? (
                <Spinner animation='border' className='accept-btn-show-btn' />
              ) : (
                "Upload"
              )}
            </button>
            <p className='text-danger'>{imageError}</p>
          </div>

          <Form onSubmit={handleSubmit(handleProfile)}>
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='First Name'
                {...register("firstName")}
              />
              {!!errors?.firstName && (
                <p className='error-msg'>{errors?.firstName.message}</p>
              )}
            </Form.Group>
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Last Name'
                {...register("lastName")}
                pattern="[A-Za-z]{1,32}" title="Please enter valid name"
              />
           
            </Form.Group>
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='text'
                placeholder='Phone'
                {...register("phoneNumber")}
              />
              {!!errors?.phoneNumber && (
                <p className='error-msg'>{errors?.phoneNumber.message}</p>
              )}
            </Form.Group>
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Email'
                {...register("email")}
              />
              {!!errors?.email && (
                <p className='error-msg'>{errors?.email.message}</p>
              )}
            </Form.Group>

            <Button className='save-btn' type='submit' disabled={loader}>
              {loader === true ? (
                <Spinner animation='border' className='accept-btn-show-btn' />
              ) : (
                <span>SAVE CHANGES</span>
              )}
            </Button>
          </Form>
        </div>
      </div>
    </Profile>
  );
}

export default ProfilePage;






