import React from "react";
import { Table } from "react-bootstrap";
import coin from "../../../assets/images/header/gem.png";
import {Tabs, Tab} from 'react-bootstrap'

const DiceHistory = () => {
  return(
  <div className="dice-tab-grid">
    <div className="dice-tab">
        <Tabs defaultActiveKey="allbet" id="uncontrolled-tab-example">
          <Tab eventKey="allbet" title="All Bets">
            <HistoryTable />
          </Tab>
          <Tab eventKey="lucky" title="Lucky Wins">
            <HistoryTable />
          </Tab>
          <Tab eventKey="mybet" title="My Bets">
            <HistoryTable />
          </Tab>
        
        </Tabs>
      </div>
  </div>
)};

export default DiceHistory;

const HistoryTable = () => {
  return( 
  <div className="dice-history">
    <Table striped bordered variant="dark" responsive>
      <thead>
        <tr>
          <th>Game</th>
          <th>User</th>
          <th>Time</th>
          <th>Wagered </th>
          <th>Multiplier</th>
          <th>Profit</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Dice</td>
          <td>Dayhzz</td>
          <td>4:09 PM</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
          <td>2.00x</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
        </tr>
        <tr>
          <td>Dice</td>
          <td>Dayhzz</td>
          <td>4:09 PM</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
          <td>2.00x</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
        </tr>
        <tr>
        <td>Dice</td>
          <td>Dayhzz</td>
          <td>4:09 PM</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
          <td>2.00x</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
        </tr>
        <tr>
        <td>Dice</td>
          <td>Dayhzz</td>
          <td>4:09 PM</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
          <td>2.00x</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
        </tr>
        <tr>
        <td>Dice</td>
          <td>Dayhzz</td>
          <td>4:09 PM</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
          <td>2.00x</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
        </tr>
        <tr>
        <td>Dice</td>
          <td>Dayhzz</td>
          <td>4:09 PM</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
          <td>2.00x</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
        </tr>
        <tr>
        <td>Dice</td>
          <td>Dayhzz</td>
          <td>4:09 PM</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
          <td>2.00x</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
        </tr>
        <tr>
        <td>Dice</td>
          <td>Dayhzz</td>
          <td>4:09 PM</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
          <td>2.00x</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
        </tr>
        <tr>
        <td>Dice</td>
          <td>Dayhzz</td>
          <td>4:09 PM</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
          <td>2.00x</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
        </tr>
        <tr>
        <td>Dice</td>
          <td>Dayhzz</td>
          <td>4:09 PM</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
          <td>2.00x</td>
          <td><div className="transaction-value"><img src={coin} alt="" /> $259.25</div></td>
        </tr>
      </tbody>
    </Table>

  </div>
)}