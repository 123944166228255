import React,{useState} from 'react';
import { Button, Form } from 'react-bootstrap';
import Layout from '../../layout/layout';
import tower from '../../../assets/images/game/tower.svg';
import singelgem from '../../../assets/images/header/gem.png';
import coin from '../../../assets/images/header/gem.png';
import './tower.css';
import DiceHistory from '../dice/history';
import gemicon from '../../../assets/images/mines/gemicon.svg'
import bomb from '../../../assets/images/mines/red-tile.svg';

const Tower = () => {
  // easy 1in4, medium 1in3, hard 1in2, advanced 2in3, boss 3in4
  const [difficulty, setDifficulty] = useState('easy');
  const [activeGame, setActiveGame] = useState(false);
  const [lostBet, setLostBet] = useState(false);
  const [betSize, setBetSize] = useState('');

  const handleSetDifficulty = (arg) => {
    setDifficulty(arg);
  };
  
  const handleStartGame = () => {
    setActiveGame(!activeGame);
    setLostBet(false);
  }

  const updateBetSize = (updateType) => {
    var newBetSize = 0;

    if (updateType === "/2")
      newBetSize = betSize / 2;
    else if (updateType === "x2")
      newBetSize = betSize * 2;
    else if (updateType === "Max")
      newBetSize = 69;
    else if (updateType === "Clear") {
      setBetSize('');
      return;
    }

    var roundedBetSize = Math.round((newBetSize + Number.EPSILON) * 100) / 100;
    if (roundedBetSize> 100000)
      return;

    setBetSize(roundedBetSize);
  }

  const updateBetSizeAmount = (event) => {
    var inputValue = parseFloat(event.target.value);
    if (inputValue > 1000000 || inputValue < 0)
      return;

    var roundedBetSize = Math.round((inputValue + Number.EPSILON) * 100) / 100;
    setBetSize(roundedBetSize);
  }

  return (
    <Layout>
      <div className='tower-page'>
        <div className='container'>
          <div className='tower-title'>
            {/* ******For Tower Main Heading Start******* */}

            { activeGame ? null : <div className='tower-heading'>
              <h2>
                <img src={tower} alt='tower' />
                Tower
              </h2>
            </div> }

            {/* ******For Tower Main Heading End******* */}

            {/* ******For Cash Out Heading Start******* */}

            { activeGame ? 
              <div className='cash-out'>
                <div className="triangle triangle1 triangle-6"></div>
                  <h2>
                    Cashout at
                    <img src={singelgem} alt='gem' />
                    <span>10.59</span>
                  </h2>
                <div className="triangle triangle2 triangle-6"></div>
                <div className="triangle triangle3 triangle-6"></div>
              </div> 
            : null}

            {/* ******For Cash Out Heading End******* */}

            {/* ******For You Lost Heading Start******* */}

            { activeGame && lostBet ?
              <div className='tower-lost'>
                <h2>
                  You lost
                  <img src={singelgem} alt='gem' />
                  <span>10.59</span>
                </h2>
              </div> 
            : null}
          </div>
          {/* ******For You Lost Heading End******* */}

          <div className='tower-body'>
            <div className='tower-main-box winner-tower-game'>

            <div 
              className={
                difficulty === "easy" ||  difficulty === "boss" ? "tower-list-row tower-list-row-four" : 
                difficulty === "medium" ||  difficulty === "advanced" ? "tower-list-row tower-list-row-three" :
                "tower-list-row tower-list-row-two"
              }>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                {difficulty === "easy" || difficulty === "boss" ?
                <>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                </>
                : difficulty === "medium" || difficulty === "advanced" ? 
                <div className='tower-box alt-winner-tower-box'>
                  <img src={gemicon} alt='gem' />                   
                </div>
                : null}
              </div>

            <div               
              className={
                difficulty === "easy" ||  difficulty === "boss" ? "tower-list-row tower-list-row-four" : 
                difficulty === "medium" ||  difficulty === "advanced" ? "tower-list-row tower-list-row-three" :
                "tower-list-row tower-list-row-two"
              }>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                {difficulty === "easy" || difficulty === "boss" ?
                <>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                </>
                : difficulty === "medium" || difficulty === "advanced" ? 
                <div className='tower-box alt-winner-tower-box'>
                  <img src={gemicon} alt='gem' />                   
                </div>
                : null}
              </div>

            <div               
              className={
                difficulty === "easy" ||  difficulty === "boss" ? "tower-list-row tower-list-row-four" : 
                difficulty === "medium" ||  difficulty === "advanced" ? "tower-list-row tower-list-row-three" :
                "tower-list-row tower-list-row-two"
              }>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                {difficulty === "easy" || difficulty === "boss" ?
                <>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                </>
                : difficulty === "medium" || difficulty === "advanced" ? 
                <div className='tower-box alt-winner-tower-box'>
                  <img src={gemicon} alt='gem' />                   
                </div>
                : null}
              </div>

            <div               
              className={
                difficulty === "easy" ||  difficulty === "boss" ? "tower-list-row tower-list-row-four" : 
                difficulty === "medium" ||  difficulty === "advanced" ? "tower-list-row tower-list-row-three" :
                "tower-list-row tower-list-row-two"
              }>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                {difficulty === "easy" || difficulty === "boss" ?
                <>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                </>
                : difficulty === "medium" || difficulty === "advanced" ? 
                <div className='tower-box alt-winner-tower-box'>
                  <img src={gemicon} alt='gem' />                   
                </div>
                : null}
              </div>

            <div               
              className={
                difficulty === "easy" ||  difficulty === "boss" ? "tower-list-row tower-list-row-four" : 
                difficulty === "medium" ||  difficulty === "advanced" ? "tower-list-row tower-list-row-three" :
                "tower-list-row tower-list-row-two"
              }>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                {difficulty === "easy" || difficulty === "boss" ?
                <>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                </>
                : difficulty === "medium" || difficulty === "advanced" ? 
                <div className='tower-box alt-winner-tower-box'>
                  <img src={gemicon} alt='gem' />                   
                </div>
                : null}
              </div>

            <div               
              className={
                difficulty === "easy" ||  difficulty === "boss" ? "tower-list-row tower-list-row-four" : 
                difficulty === "medium" ||  difficulty === "advanced" ? "tower-list-row tower-list-row-three" :
                "tower-list-row tower-list-row-two"
              }>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                {difficulty === "easy" || difficulty === "boss" ?
                <>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                </>
                : difficulty === "medium" || difficulty === "advanced" ? 
                <div className='tower-box alt-winner-tower-box'>
                  <img src={gemicon} alt='gem' />                   
                </div>
                : null}
              </div>

              <div               
                className={
                  difficulty === "easy" ||  difficulty === "boss" ? "tower-list-row current-tower-row tower-list-row-four" : 
                  difficulty === "medium" ||  difficulty === "advanced" ? "tower-list-row current-tower-row tower-list-row-three" :
                  "tower-list-row current-tower-row tower-list-row-two"
              }>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                <div className='tower-box'>
                  <h6>
                    <img src={singelgem} alt='gem' />
                    10.59
                  </h6>
                </div>
                {difficulty === "easy" || difficulty === "boss" ?
                <>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                  <div className='tower-box'>
                    <h6>
                      <img src={singelgem} alt='gem' />
                      10.59
                    </h6>
                  </div>
                </>
                : difficulty === "medium" || difficulty === "advanced" ? 
                <div className='tower-box alt-winner-tower-box'>
                  <img src={gemicon} alt='gem' />                   
                </div>
                : null}
              </div>

              <div               
                className={
                  difficulty === "easy" ||  difficulty === "boss" ? "tower-list-row tower-list-row-four" : 
                  difficulty === "medium" ||  difficulty === "advanced" ? "tower-list-row tower-list-row-three" :
                  "tower-list-row tower-list-row-two"
              }>
                <div className='tower-box loser-tower-box'>
                  <img src={bomb} alt='gem' />
                </div>
                <div className='tower-box alt-winner-tower-box'>
                  <img src={gemicon} alt='gem' />
                </div>
                {difficulty === "easy" || difficulty === "boss" ?
                <>
                  <div className='tower-box alt-winner-tower-box'>
                    <img src={gemicon} alt='gem' />                   
                  </div>
                  <div className='tower-box alt-winner-tower-box'>
                      <img src={gemicon} alt='gem' />                   
                  </div>
                </>
                : difficulty === "medium" || difficulty === "advanced" ? 
                <div className='tower-box alt-winner-tower-box'>
                  <img src={gemicon} alt='gem' />                   
                </div>
                : null}
              </div>

              <div               
                className={
                  difficulty === "easy" ||  difficulty === "boss" ? "tower-list-row tower-list-row-four" : 
                  difficulty === "medium" ||  difficulty === "advanced" ? "tower-list-row tower-list-row-three" :
                  "tower-list-row tower-list-row-two"
              }>
                <div className='tower-box bomb-tower-box'>
                  <img src={bomb} alt='gem' />
                </div>
                <div className='tower-box alt-winner-tower-box'>
                  <img src={gemicon} alt='gem' />
                </div>
                {difficulty === "easy" || difficulty === "boss" ?
                <>
                  <div className='tower-box winner-tower-box'>
                    <img src={gemicon} alt='gem' />                   
                  </div>
                  <div className='tower-box alt-winner-tower-box'>
                      <img src={gemicon} alt='gem' />                   
                  </div>
                </>
                : difficulty === "medium" || difficulty === "advanced" ? 
                <div className='tower-box alt-winner-tower-box'>
                  <img src={gemicon} alt='gem' />                   
                </div>
                : null}
              </div>

            </div>

            <div className='tower-bet-box'>
              <div className='roll-dice'>
                <Button onClick={handleStartGame}><span>{activeGame ? "Cashout" : "Place Bet" }</span></Button>
              </div>
              <Form>
                <Form.Group>
                  <Form.Label>Bet Amount</Form.Label>
                  <div className='bet-input pdd-200'>
                    <img src={coin} alt='gem' />
                    <Form.Control className='bet-size-input' type='number' placeholder='0.00' value={betSize} onChange={updateBetSizeAmount} />
                    <div className='bet-input-btn'>
                      <Button onClick={() => updateBetSize("/2")}>1/2</Button>
                      <Button onClick={() => updateBetSize("x2")}>2x</Button>
                      <Button onClick={() => updateBetSize("Max")}>Max</Button>
                      <Button onClick={() => updateBetSize("Clear")}>Clear</Button>
                    </div>
                  </div>
                </Form.Group>
              </Form>
              <div className='level-btn'>
                <Button onClick={()=>handleSetDifficulty('easy') } className={`${difficulty==='easy' && "active-level-btn"}`}>Easy</Button>
                <Button onClick={()=>handleSetDifficulty('medium') } className={`${difficulty==='medium' && "active-level-btn"}`} >Medium</Button>
                <Button onClick={()=>handleSetDifficulty('hard') } className={`${difficulty==='hard' && "active-level-btn"}`}>Hard</Button>
                <Button onClick={()=>handleSetDifficulty('advanced') } className={`${difficulty==='advanced' && "active-level-btn"}`}>Advanced</Button>
                <Button onClick={()=>handleSetDifficulty('boss') } className={`${difficulty==='boss' && "active-level-btn"}`}>Boss</Button>
                {/* <ButtonGroup>
                  <ToggleButton
                    id='1'
                    type='radio'
                    className={`.btn-check ${
                      radioValue === '1' ? 'outline-success' : 'outline-danger'
                    }`}
                    name='radio'
                    value='1'
                    checked={radioValue === '1'}
                    onChange={(e) => setRadioValue(e.target.value)}
                  >
                    Easy
                  </ToggleButton>
                  <ToggleButton
                    id='2'
                    type='radio'
                    className={`.btn-check ${
                      radioValue === '1' ? 'outline-success' : 'outline-danger'
                    }`}
                    name='radio'
                    value='2'
                    checked={radioValue === '2'}
                    onChange={(e) => setRadioValue(e.target.value)}
                  >
                    Medium
                  </ToggleButton>
                  <ToggleButton
                    key=''
                    id='3'
                    type='radio'
                    className={`.btn-check ${
                      radioValue === '1' ? 'outline-success' : 'outline-danger'
                    }`}
                    name='radio'
                    value='3'
                    checked={radioValue === '3'}
                    onChange={(e) => setRadioValue(e.target.value)}
                  >
                    Hard
                  </ToggleButton>
                </ButtonGroup> */}
              </div>
            </div>

          </div>
          <DiceHistory />
        </div>
      </div>
    </Layout>
  );
};

export default Tower;
