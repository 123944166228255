import React, { useContext, useState } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import Chat from "../chat/chat";
import Sidebar from "../sidebar/sidebar";
import { useEffect } from "react";
import UserContext from "../../context/UserContext";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useRef } from "react";

const Layout = ({ children }) => {
  let disableMenu = false;
  const [show, setShow] = useState(false);
  const [glassy, setGlassy] = useState(false);
  const [search, setSearch] = useState(false);
  const { openSidebar, setOpenSidebar } = useContext(UserContext);
  const handleSidebar = () => {
    setOpenSidebar(!openSidebar);
    localStorage.setItem("sidebar", true);
    if (openSidebar) {
      localStorage.removeItem("sidebar");
    }
  };

  const { loader } = useContext(UserContext);
  const handleGlassy = () => {
    setGlassy(!glassy);
  };

  useEffect(() => {
    const pathname = window.location;
    if (pathname.pathname === "/search") {
      setSearch(true);
    }
  }, []);

  const wrapperRef = useRef();
  function useOutsideAlerter(ref) {
    useEffect(() => {

      function handleClickOutside(event) {
        if ((ref.current && !ref.current.contains(event.target))) {
          localStorage.removeItem("sidebar");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {

        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);
  const outsideHandler = () => {
    setOpenSidebar(false);
  }

  useEffect(() => {
    window.addEventListener("message", (event) => {
      const { type, isGameRunning } = event?.data
      if (type === "isGameRunning") {
        if (isGameRunning) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          disableMenu = true
        } else {
          disableMenu = false
        }
      }
    })
  }, [])

  return (
    <div
      className={`wrapper ${localStorage.getItem("sidebar") ? "opensidebar" : ""
        } ${glassy ? "glassy-effect" : ""
        }`}
    >
      <Helmet>
        <html lang="en" className={`homepage ${disableMenu ? 'disable-menu' : ''} ${localStorage.getItem("sidebar") ? "c" : ""
          }`} />
      </Helmet>
      {loader &&
        <div className="home-loader">
          <Spinner animation="border" variant="warning" /></div>
      }


      <Header handleSidebar={handleSidebar} search={search} />

      <div className="content-wrapper" onClick={outsideHandler}>
        <div className={`sidebar-wrapper`} ref={wrapperRef}>
          <Sidebar handleGlassy={handleGlassy} glassy={glassy} />
        </div>
        <div className={`content ${localStorage.getItem("sidebar") ? "contentbg" : ""
        }`} >
          {children}
          <Footer
            show={show}
            setShow={setShow}
            handleSidebar={handleSidebar}
            openSidebar={openSidebar}
            search={search}
          />
        </div>
        <div
          className={`chat-wrapper ${!show ? 'chat-hide' : ''}`}
        >
          <Chat show={show} setShow={setShow} />
        </div>
      </div>

    </div>
  );
};

export default Layout;
