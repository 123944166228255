import React, { useContext, useState } from "react";
import { Button, Form, Modal, Nav } from "react-bootstrap";
import gem from "../../assets/images/header/gem.png";
import arrow from "../../assets/images/sidebar/arrow.svg";
import rouletteicon from "../../assets/images/sidebar/roulette.svg";
// import diceicon from "../../assets/images/sidebar/dice.svg";
import minesicon from "../../assets/images/sidebar/mines.svg";
import sloticon from "../../assets/images/sidebar/slots.svg";
import minesActive from "../../assets/images/sidebar/mines-active.svg";
// import diceActive from "../../assets/images/sidebar/dice-active.svg";
import slotsActive from "../../assets/images/sidebar/slots-active.svg";
import roulettenotActive from "../../assets/images/sidebar/roulette-notActive.svg";
import ethereumsm from "../../assets/images/coins/ethereum-sm.svg";
import ethereummd from "../../assets/images/coins/ethereum-md.svg";
import Cashier from "../modals/cashier";
import qr from "../../assets/images/coins/qr.svg";
import danger from "../../assets/images/coins/danger.svg";
import dollar from "../../assets/images/coins/dollar-black.svg";
import singelgem from "../../assets/images/header/gem.png";
import userimg from "../../assets/images/home/user.png";

import "./sidebar.css";
import menu1 from "../../assets/images/header/menu1.svg";
import menu5 from "../../assets/images/header/menu5.svg";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import UserContext from "../../context/UserContext";
import { handleLogout } from "../../utils/Api";
// import Cashier from "../modals/cashier";

const Sidebar = () => {
  const { user, setUser } = useContext(UserContext);
  const [hideGames, setHidegames] = useState(false);
  const [show, setShow] = useState(false);

  const navigate = useHistory();

  const location = useLocation();

  const handleGames = () => {
    setHidegames(!hideGames);
  };

  const handleNav = (url) => {
    navigate.push(url);
    localStorage.removeItem("sidebar");
  };

  const handleModal = (e) => {
    setShow(e);
  };

  return (
    <div className="sidebar-content">
      <div className={`sidebar-menu ${hideGames ? "hide-games" : ""}`}>
        {(user?.id || user?._id) && (
          <div className="my-profile-menu my-profile-menu_sidebar">
            <div className="p-name">
              <div className="d-flex align-items-center flex-row-reverse pb-3 my-profile-image">
                <p>
                  {user?.firstName ? user?.firstName : ""}{" "}
                  {user?.lastName ? user?.lastName : ""}
                </p>
                <div className="p-pic ">
                  <img src={user?.avatar ? user?.avatar : userimg} alt="" />
                </div>
              </div>
              <div className="nav1 ">
                <span
                  className={`nav-link ${
                    location.pathname === "/profile" ? "active" : ""
                  }`}
                  onClick={() => handleNav("/profile")}
                >
                  <img src={menu1} alt="" /> <span>My Profile</span>
                </span>
                <span
                  className={`nav-link`}
                  onClick={() => handleLogout(user, setUser)}
                >
                  {" "}
                  <img src={menu5} alt="" /> Log Out
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="sidebar-menu-header" onClick={handleGames}>
          <h4>
            Winit Originals <img src={arrow} alt="" />
          </h4>
        </div>
        <div className="sidebar-menu-body">
          <Nav>
            <span
              className={`nav-link ${
                location.pathname === "/slot" ? "active" : ""
              }`}
              onClick={() => handleNav("/slot")}
            >
              <img src={slotsActive} alt="" className="active-img" />{" "}
              <img src={sloticon} alt="" className="navbar-img" />{" "}
              <span>Slots</span>
            </span>
            <span
              className={`nav-link ${
                location.pathname === "/limbo" ? "active" : ""
              }`}
              exact
              onClick={() => handleNav("/limbo")}
            >
              <img src={rouletteicon} alt="" className="active-img" />{" "}
              <img src={roulettenotActive} alt="" className="navbar-img" />{" "}
              <span>Limbo</span>
            </span>
            {/* <span
              className={`nav-link ${location.pathname === "/crash" ? "active" : ""
                }`}
              onClick={() => handleNav("/crash")}
            >
              <img src={diceActive} alt="" className="active-img" />{" "}
              <img src={diceicon} alt="" className="navbar-img" />{" "}
              <span>Crash</span>
            </span> */}
            <span
              className={`nav-link ${
                location.pathname === "/plinko" ? "active" : ""
              }`}
              onClick={() => handleNav("/plinko")}
            >
              <img src={minesActive} alt="" className="active-img" />{" "}
              <img src={minesicon} alt="" className="navbar-img" />{" "}
              <span>Plinko</span>
            </span>
          </Nav>
        </div>
      </div>
      <div></div>
      <div className="claim-rake">
        <h4>Deposit</h4>
        <p>Instantly claim your rewards!</p>
        <Button onClick={() => handleModal("cashier")}>
          Deposit <img src={gem} alt="" />
        </Button>
      </div>
      {/*------------------------------- modal casier-------------------- */}

      <Modal
        className="cashier-modals"
        show={show === "cashier"}
        onHide={() => handleModal("")}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cashier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cashier handleModal={handleModal} />
        </Modal.Body>
      </Modal>

      {/* -----------deposit---------- */}
      <Modal
        className="qr-modal"
        show={show === "bitcoin-deposit"}
        onHide={() => handleModal("")}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cashier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="qr-code-modal">
            <div className="qr-coin-type">
              <div className="qr-back-arrow" onClick={() => setShow("cashier")}>
                <img src={arrow} alt="" />
              </div>
              <div className="qr-coin-name">
                <h6>
                  <img src={ethereumsm} alt="" />
                  Ethereum
                </h6>
                <p>Crypto</p>
              </div>
            </div>
            <div className="address-form">
              <Form>
                <Form.Group>
                  <Form.Label>Deposit Address</Form.Label>
                  <div className="ref-input">
                    <Form.Control
                      type="text"
                      placeholder="0xec6661f21a352ca572a1bc11738c62e2407146c9"
                    />
                    <div className="ref-input-btn ref-btn-copy">
                      <Button className="ref-btn-copy">
                        <span>Copy</span>
                      </Button>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </div>
            <div className="qr-address">
              <img src={qr} alt="" />
            </div>
            <div className="warning-text">
              <img src={danger} alt="" />
              <p>
                Only send ETH to this address, 12 confirmation(s) required. We
                do not accept BEP20 from Binance.
              </p>
            </div>
            <div className="coin-compare">
              <div className="coin-compare-box">
                <img src={dollar} alt="" />
                <input type="text" placeholder="10.00" />
              </div>
              <span>=</span>
              <div className="coin-compare-box">
                <img src={singelgem} alt="" />
                <input type="text" placeholder="10.00" />
              </div>
              <span>=</span>
              <div className="coin-compare-box">
                <img src={ethereummd} alt="" />
                <input type="text" placeholder="10.00" />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* *********** Deposit Modal End ********** */}

      {/* *********** Withdraw Modal Start ********** */}

      <Modal
        className="qr-modal"
        show={show === "bitcoin-withdraw"}
        onHide={() => handleModal("")}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cashier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="qr-code-modal">
            <div className="qr-coin-type">
              <div className="qr-back-arrow" onClick={() => setShow("cashier")}>
                <img src={arrow} alt="" />
              </div>
              <div className="qr-coin-name">
                <h6>
                  <img src={ethereumsm} alt="" />
                  Ethereum
                </h6>
                <p>Crypto</p>
              </div>
            </div>
            <div className="address-form">
              <Form>
                <Form.Group>
                  <Form.Label>Ethereum Address</Form.Label>
                  <div className="ref-input">
                    <Form.Control type="text" placeholder="" />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <div className="ref-input">
                    <Form.Control type="number" placeholder="0.00" />
                    <div className="ref-input-btn ">
                      <Button className="ref-btn-copy">MAX</Button>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </div>
            <div className="withdarw-btn">
              <Button>WITHDRAW</Button>
            </div>
            <div className="warning-text">
              <img src={danger} alt="" />
              <p>
                Minimum withdrawl is $22.59. Your withdrawl will have a fee of
                $8.90.
              </p>
            </div>
            <div className="coin-compare">
              <div className="coin-compare-box">
                <img src={dollar} alt="" />
                <input type="text" placeholder="10.00" />
              </div>
              <span>=</span>
              <div className="coin-compare-box">
                <img src={singelgem} alt="" />
                <input type="text" placeholder="10.00" />
              </div>
              <span>=</span>
              <div className="coin-compare-box">
                <img src={ethereummd} alt="" />
                <input type="text" placeholder="10.00" />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Sidebar;
