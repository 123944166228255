import openSocket from "socket.io-client";
import { server } from "./keys";
import { getCookie } from "../utils/cookies";

const socket = openSocket(server, {
  transports: ["websocket"],
  rejectUnauthorized: false,
  reconnection: false,
});

socket.on("connect", () => {
  const userid = getCookie("userid");
  if ( userid) {
    socket.emit("join", userid);
  }
});

const tryReconnect = () => {
  setTimeout(() => {
    socket.io.open((err) => {
      if (err) {
        tryReconnect();
      }
    });
  }, 2000);
};

socket.io.on("close", tryReconnect);

export default socket;
