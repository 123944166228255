import React, { useState, useEffect, useContext } from "react";
import Profile from "./profile";
import { Button, Table } from "react-bootstrap";
import { getBetHistoryData, handleLogout } from "../../utils/Api";
import ReactPaginate from "react-paginate";
import singelgem from "../../assets/images/header/gem.png";
import UserContext from "../../context/UserContext";
import { debounce } from "lodash";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../loader/loader";
import NoData from "../loader/noData";


const BetHistory = () => {

  const { user, setUser } = useContext(UserContext);
  const initialState = {
    currentPage: 1,
    searchTerm: "",
  };

  const [managedState, setmanagedState] = useState(initialState);
  const [mainLoading, setMainLoading] = useState(true);
  const [dataList, setdataList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = (data) => {
    setMainLoading(true);
    const selectedPage = data.selected + 1;
    setmanagedState((prev) => {
      return { ...prev, currentPage: selectedPage };
    });
  };

  

  const handleSearchTermChange = (event) => {
    setmanagedState((prev) => {
      return { ...prev, currentPage: 1, searchTerm: event.target.value };
    });
  };

  

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getcashappData = async () => {
      setMainLoading(true);
      const response = await getBetHistoryData(managedState, source.token);
      setMainLoading(false);
      if (response.code === 200) {
        setdataList(response.transactions);
        setTotalPages(response.totalPages);
      } else {
        toast.error(response.message, {
          toastId: "error-while-fetching",
        });
        if (response.message === "Please authenticate") {
         await handleLogout(user,setUser);
        }
      }
    };

    const debouncedFetchData = debounce(getcashappData, 600);

    debouncedFetchData();

    return () => {
      source.cancel("Request canceled");
    };
    // eslint-disable-next-line
  }, [managedState]);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function getMonthName(month) {
    const d = new Date();
    d.setMonth(month - 1);
    const monthName = d.toLocaleString("default", { month: "long" });
    return monthName;
  }

  const getDate = (d) => {
    const date = new Date(d);
    const day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // convert to 12-hour format
    month = getMonthName(month);
    return `${day} ${month} ${year} ${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    } ${ampm}`;
  };

  const checkProfit = (profit) => {
    if (profit < 0) {
      let newProfit = Math.abs(profit);
      return `-$${newProfit?.toFixed(2)}`;
    } else {
      return `$${profit?.toFixed(2)}`;
    }
  };
  
  return (
    <Profile>
      <div className='bet-history'>
        <div className='page-heading'>
          <h3>Bet History</h3>
          <div className='search-wrapper'>
            <div className='search-box transaction_inp'>
              <input
                type='text'
                placeholder='Search by Game Name '
                value={managedState.searchTerm}
                onChange={handleSearchTermChange}
                // className="transaction_inp"
              />
              <i className='bi bi-search'></i>
            </div>
          </div>
        </div>

        <div className='bet-history-content'>
          <Table striped bordered variant='dark' responsive>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Game</th>
                <th>Date & Time</th>
                <th>Wagered </th>
                <th>Multiplier</th>
                <th>Profit</th>
              </tr>
            </thead>

            <tbody>
              {!mainLoading ? (
                dataList.length > 0 ? (
                  dataList.map((ele, index) => (
                    <tr key={index}>
                      <td>{managedState?.currentPage * 10 - 9 + index}</td>
                      <td>{capitalizeFirstLetter(ele.gameType)}</td>
                      <td>{getDate(ele.createdAt)}</td>
                      <td>
                        <div className='transaction-value'>
                          <img src={singelgem} alt='' />{" "}
                          {checkProfit(ele?.amount)}
                        </div>
                      </td>
                      <td>{ele?.multiplier ? `${ele?.multiplier}x` : "-"}</td>
                      <td>
                        {ele.profit?.toString()?.startsWith("-") ? (
                          <div className='transaction-value-loss'>
                            {` $${ele.profit.toString().split("-").join("")}`}
                          </div>
                        ) : (
                          <div className='transaction-value'>
                            {ele?.profit ? `$ ${ele?.profit.toFixed(2)}` : <p className="transaction-value-loss">0</p>}
                          </div>
                        )}
                        {/* {ele?.profit ? <div className='transaction-value'>
                        <img src={singelgem} alt='' />{" "}
                        {checkProfit(ele?.profit)}
                      </div> : '-'} */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoData />
                )
              ) : (
                <Loader />
              )}
            </tbody>
          </Table>
          {totalPages > 1 && (
            <ReactPaginate
              nextLabel={
                <Button className=' nextBtn'>
                  Next
                  {/* <i class="bi bi-arrow-right"></i> */}
                </Button>
              }
              onPageChange={handlePageChange}
              pageRangeDisplayed={1}
              marginPagesDisplayed={2}
              pageCount={totalPages}
              previousLabel={
                <Button className=' prevBtn'>
                  {/* <i class="bi bi-arrow-left"></i> */}
                  Previous
                </Button>
              }
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              forcePage={parseInt(managedState.currentPage) - 1}
              // renderOnZeroPageCount={null}
            />
          )}
        </div>
      </div>
    </Profile>
  );
};

export default BetHistory;
