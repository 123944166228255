import React from 'react';
import ComingSoon from './index';
import Layout from '../layout/layout';
import './style.css';

const CrazyTime = () => {
  return (
    <Layout>
      <ComingSoon />
    </Layout>
  );
};

export default CrazyTime;
