import React, { useContext, useState } from "react";
import { Button, Modal, Dropdown, Spinner } from "react-bootstrap";
import Login from "../login/login";
import Register from "../register/register";
import Forget from "../forget/forget";
import Reset from "../forget/reset";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/header/logo.png";
import logo1 from "../../assets/images/header/mbl-winit-logo.svg";
import { toast } from "react-toastify";
import userimg from "../../assets/images/home/user.png";
import gem from "../../assets/images/header/gem.png";
import menu1 from "../../assets/images/header/menu1.svg";
import menu5 from "../../assets/images/header/menu5.svg";
import ethereumsm from "../../assets/images/coins/ethereum-sm.svg";
import ethereummd from "../../assets/images/coins/ethereum-md.svg";
import arrow from "../../assets/images/coins/arrow-left.svg";
import Cashier from "../modals/cashier";
import qr from "../../assets/images/coins/qr.svg";
import danger from "../../assets/images/coins/danger.svg";
import dollar from "../../assets/images/coins/dollar-black.svg";
import singelgem from "../../assets/images/header/gem.png";
import "./header.css";
import UserContext from "../../context/UserContext";
import VerifyOtp from "../otpVerify/otpVerify";
import { useEffect } from "react";
import { handleLogout, reverifyEmail } from "../../utils/Api";
import { getCookie } from "../../utils/cookies";

const Header = ({ handleSidebar }) => {
  const { user, setUser, resetModal, isCheckEmailVerification, setIsCheckEmailVerification, storeEmailForReverification } = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);

  const handleModal = (e) => {
    setShow(e);
  };
  const handleCloseModal = () => {
    setShow(false)
  }

  useEffect(() => {
    if (resetModal) {
      handleModal("reset")
    }

  }, [resetModal])

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event?.data === "loginRequest") {
        if (!getCookie("token")) {
          handleModal("login")
        }
      }
      if (event?.data?.type === 'testModeDisable') {
        let mssg = 'Test mode is ' + event.data.msg;
        toast.error(mssg, { toastId: "plinko-test-mode" + event.data.testMode });
      }
      if (event?.data?.type === 'testMode') {
        let mssg = 'Test mode is ' + event.data.msg;
        toast.success(mssg, { toastId: "plinko-test-mode" + event.data.testMode });
      }

      if (event?.data?.type === 'errorMessageShow') {

        toast.error(event.data.msg, { toastId: "limbo-test-mode" });
      }
      if (event?.data === "gameRunning") {
        toast.error("Game is runing", { toastId: "dropChips" });
      }
      if (event?.data === "userNotfound") {
        toast.error("User data not found. Please refresh.", {
          toastId: "dropChips",
        });
      }
      if (event?.data === "walletUpdate") {
        toast.error("Please recharge your wallet.", { toastId: "dropChips" });
      }
      if (event?.data?.type === 'errorResponse') {
        let mssg = event.data.msg;
        toast.error(mssg, { toastId: "error" });
      }

    })
  }, [])

  const handleReverification = async (values) => {
    setLoader(true);
    const res = await reverifyEmail(values);
    if (res.data.msg === "Email send for verification") {
      window.location.href = '/verify-notification'
    }
    setLoader(false);
  }
  return (
    <div className="header">
      {isCheckEmailVerification ?
        <div className="activate-account">
          <Button className="close" onClick={() => setIsCheckEmailVerification(false)}>
            <i className="fa fa-times" />
          </Button>
          <p>
            Please activate the account by {' '}
            <Button className="verify" onClick={() => handleReverification({ email: storeEmailForReverification })}>
              {loader ? (
                <Spinner
                  animation='border' size="sm" className="accept-btn-show-btn"

                />
              ) : (
                "verifying your email"
              )}
            </Button>
          </p>
        </div>
        :
        <div className="header-top-area">
          <div className="header-logo-menu">
            <div className="menu-icon menu-icon_left">
              <Button onClick={handleSidebar} className="navbar__menu">
                <span />
              </Button>
            </div>
            {/* <div className={`search-icon ${search ? "active-search" : ""}`}>
            <Link to="/">
              {search ? (
                <img src={searchActive} alt="" />
              ) : (
                <img src={searchicon} alt="" />
              )}
            </Link>
          </div> */}
            <div className="header-logo">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="mbl-logo">
              <Link to="/">
                <img src={logo1} alt="" />
              </Link>
            </div>
          </div>
          {!user ? (
            <><div className="loggedoff-menu header-top-login">
              <ul>
                <li>
                  <span role="presentation" onClick={() => handleModal("login")}>
                    Login
                  </span>
                </li>
                <li>
                  <span
                    role="presentation"
                    onClick={() => handleModal("register")}
                  >
                    Register
                  </span>
                </li>
              </ul>
              <div className="menu-icon menu-icon_right">
                <Button onClick={handleSidebar} className="navbar__menu">
                  <span />
                </Button>
              </div>
            </div>
            </>
          ) : (
            ""
          )}
          {(user) && (
            <>
              <div className="loggedin-menu">
                <div className="credit-input">
                  <Form>
                    <img src={gem} alt="coin" />
                    <Form.Control
                      type="number"
                      placeholder={Math.floor(user?.wallet * 100) / 100}
                      name="amount"
                      autoComplete="off"
                    />
                    <Button onClick={() => handleModal("cashier")}>
                      <span>DEPOSIT</span>
                    </Button>
                  </Form>
                </div>
              </div>
              <div className="my-profile-menu">
                <div className="p-name">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-profile">
                      {user?.firstName ? user?.firstName : ""} {user?.lastName ? user?.lastName : ""}
                      <div className="p-pic">
                        <img src={user?.avatar ? user?.avatar : userimg} alt="" />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Link to="/profile">
                        <img src={menu1} alt="" /> My Profile
                      </Link>
                      <Dropdown.Item onClick={() => handleLogout(user, setUser)}>
                        <img src={menu5} alt="" /> Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {/* <div className="notification-icon">
                <Button>
                  <img src={notification} alt="" />
                </Button>
              </div> */}
              </div>
              <div className="menu-icon menu-icon_right">
                <Button onClick={handleSidebar} className="navbar__menu">
                  <span />
                </Button>
              </div>
            </>
          )}
        </div>

      }

      <Modal
        centered
        className="login-popup"
        show={show === "login"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Login handleModal={handleModal} />
        </Modal.Body>
      </Modal>

      <Modal
        centered
        className="signup-popup"
        show={show === "register"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Register handleModal={handleModal} />
        </Modal.Body>
      </Modal>

      <Modal
        centered
        className="signup-popup"
        show={show === "verifyOTP"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VerifyOtp handleModal={handleModal} />
        </Modal.Body>
      </Modal>

      <Modal
        centered
        className="login-popup"
        show={show === "forget"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Forget handleModal={handleModal} />
        </Modal.Body>
      </Modal>

      <Modal
        centered
        className="login-popup"
        show={show === "reset"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Reset handleModal={handleModal} handleCloseModal={handleCloseModal} />
        </Modal.Body>
      </Modal>

      {/* *********** Cashier Modal Start ********** */}
      <Modal
        className="cashier-modals"
        show={show === "cashier"}
        onHide={() => handleModal("")}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cashier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cashier handleModal={handleModal} />
        </Modal.Body>
      </Modal>
      {/* *********** Cashier Modal End********** */}

      {/* *********** Deposit Modal Start ********** */}

      <Modal
        className="qr-modal"
        show={show === "bitcoin-deposit"}
        onHide={() => handleModal("")}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cashier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="qr-code-modal">
            <div className="qr-coin-type">
              <div className="qr-back-arrow" onClick={() => setShow("cashier")}>
                <img src={arrow} alt="" />
              </div>
              <div className="qr-coin-name">
                <h6>
                  <img src={ethereumsm} alt="" />
                  Ethereum
                </h6>
                <p>Crypto</p>
              </div>
            </div>
            <div className="address-form">
              <Form>
                <Form.Group>
                  <Form.Label>Deposit Address</Form.Label>
                  <div className="ref-input">
                    <Form.Control
                      type="text"
                      placeholder="0xec6661f21a352ca572a1bc11738c62e2407146c9"
                    />
                    <div className="ref-input-btn ref-btn-copy">
                      <Button className="ref-btn-copy">
                        <span>Copy</span>
                      </Button>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </div>
            <div className="qr-address">
              <img src={qr} alt="" />
            </div>
            <div className="warning-text">
              <img src={danger} alt="" />
              <p>
                Only send ETH to this address, 12 confirmation(s) required. We
                do not accept BEP20 from Binance.
              </p>
            </div>
            <div className="coin-compare">
              <div className="coin-compare-box">
                <img src={dollar} alt="" />
                <input type="text" placeholder="10.00" />
                {/* <h6>10.00</h6> */}
              </div>
              <span>=</span>
              <div className="coin-compare-box">
                <img src={singelgem} alt="" />
                <input type="text" placeholder="10.00" />
                {/* <h6>10.00</h6> */}
              </div>
              <span>=</span>
              <div className="coin-compare-box">
                <img src={ethereummd} alt="" />
                <input type="text" placeholder="10.00" />
                {/* <h6>0.0555555</h6> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* *********** Deposit Modal End ********** */}

      {/* *********** Withdraw Modal Start ********** */}

      <Modal
        className="qr-modal"
        show={show === "bitcoin-withdraw"}
        onHide={() => handleModal("")}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cashier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="qr-code-modal">
            <div className="qr-coin-type">
              <div className="qr-back-arrow" onClick={() => setShow("cashier")}>
                <img src={arrow} alt="" />
              </div>
              <div className="qr-coin-name">
                <h6>
                  <img src={ethereumsm} alt="" />
                  Ethereum
                </h6>
                <p>Crypto</p>
              </div>
            </div>
            <div className="address-form">
              <Form>
                <Form.Group>
                  <Form.Label>Ethereum Address</Form.Label>
                  <div className="ref-input">
                    <Form.Control type="text" placeholder="" />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <div className="ref-input">
                    <Form.Control type="number" placeholder="0.00" />
                    <div className="ref-input-btn ">
                      <Button className="ref-btn-copy">MAX</Button>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </div>
            <div className="withdarw-btn">
              <Button>WITHDRAW</Button>
            </div>
            <div className="warning-text">
              <img src={danger} alt="" />
              <p>
                Minimum withdrawl is $22.59. Your withdrawl will have a fee of
                $8.90.
              </p>
            </div>
            <div className="coin-compare">
              <div className="coin-compare-box">
                <img src={dollar} alt="" />
                <input type="text" placeholder="10.00" />
                {/* <h6>10.00</h6> */}
              </div>
              <span>=</span>
              <div className="coin-compare-box">
                <img src={singelgem} alt="" />
                <input type="text" placeholder="10.00" />
                {/* <h6>10.00</h6> */}
              </div>
              <span>=</span>
              <div className="coin-compare-box">
                <img src={ethereummd} alt="" />
                <input type="text" placeholder="10.00" />
                {/* <h6>0.0555555</h6> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* *********** Withdraw Modal End ********** */}
    </div>
  );
};

export default Header;
