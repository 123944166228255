import React from "react"
import Layout from "../../layout/layout";
import ManualGame from "./manualgame";
import AutomatedGame from "./automatedgame";
import DiceHistory from "./history";
import {Tabs, Tab} from 'react-bootstrap'
import PreviousResult from "./previousresult";
import "./dice.css"


const Dice = () => {
    return (
        <Layout>
            <div className="dice">
                <div className="container">

                    <div className="dice-tab">

                        <Tabs defaultActiveKey="manual" id="uncontrolled-tab-example">
                            <Tab eventKey="manual" title="Manual">
                                <ManualGame />
                            </Tab>
                            <Tab eventKey="automated" title="Automated">
                                <AutomatedGame />
                            </Tab>
                        </Tabs>

                    </div>

                    <PreviousResult />

                    <DiceHistory />
                        
                </div>
            </div>
        </Layout>
    );
};

export default Dice;