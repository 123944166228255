import React, { useContext, useEffect } from "react";
import Layout from "../../layout/layout";
import { limboUrl } from "../../../config/keys";
import "./style.css";
import UserContext from "../../../context/UserContext";

const Limbo = () => {
  const { setLoader } = useContext(UserContext);

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, [1500]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <div className="limbo-integrate">
        <iframe
        onlec
          src={limboUrl}
          title="Example Website"
          id="limbo"
          width="100%"
          height="700px"
          style={{ border: "none", background: "rgb(13 19 34)" }}
        ></iframe>
      </div>
    </Layout>
  );
};

export default Limbo;
