/* eslint-disable react-hooks/rules-of-hooks */
import { authInstance, userInstance } from "../config/axios";
import axios from 'axios';
import { toast } from "react-toastify";
import { getCookie } from "./cookies";


export const verifyEmail = async (token) => {
  try {
    const res = await authInstance.post(`/verify-email?token=${token}`);
    return res?.data;
  } catch (error) {
    return error?.response?.data;
  }
};


export const changeProfile = async (values) => {
  try {
    const response = await authInstance.post(`/update-profile`, values);
    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const changeProfileImage = async (values) => {
  try {
    const response = await authInstance.post(`/update-profile-image`, values);
    return response.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const changePassword = async (values) => {
  try {
    const response = await authInstance.post(`/change-password`, values);
    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};


export const getBetHistoryData = async (managedState, cancelToken) => {
  try {
    const payload = {
      limit: 10,
      page: managedState.currentPage,
      searchTerm: managedState.searchTerm
    }
    const response = await userInstance.post(`/get-bet-history`, payload,
      { cancelToken });
    return response?.data;
  } catch (error) {
    if (axios.isCancel(error)) {
    } else {
      return error?.response?.data;
    }
  }
};


export const getTransactionData = async (managedState, cancelToken) => {
  try {
    const payload = {
      limit: 10,
      page: managedState.currentPage,
      searchTerm: managedState.searchTerm
    }
    const response = await userInstance.post(`/get-transactions-data`, payload,
      { cancelToken });
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
    } else {
      return error?.response?.data;
    }
  }
};


export const getBetHistoryAllData = async () => {
  try {
    const response = await userInstance.get(`/get-all-bet-history`);
    return response?.data;
  } catch (error) {
    // if (axios.isCancel(error)) {
    // } else {
    //   return error?.response?.data;
    // } 
  }
};

export const getMyBetAllData = async () => {
  try {
    const response = await userInstance.get(`/get-my-bet-history`);
    return response.data;
  } catch (error) {
    // if (axios.isCancel(error)) {
    // } else {
    //   return error?.response?.data;
    // } 
  }
};

export const forgetPassword = async (values) => {
  try {
    const response = await authInstance.post(`/forgot-password`, values);
    return response;
  } catch (error) {
    return error.response.data;
  }
};


export const reverifyEmail = async (values) => {
  try {
    const response = await authInstance.post(`/re-verify-email`, values);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const handleLogout = async (user, setUser) => {
  if (user?.id || user?._id) {
    const tokenData = getCookie("refreshToken");
    if (tokenData) {
      await authInstance.post(
        "/logout",
        { refreshToken: tokenData },
        { withCredentials: true, credentials: "include" }
      );
    }
  }
  localStorage.clear();
  setUser();
  toast.success("Logout Successfully!");
  setTimeout(() => {
    window.location.href = '/';
  }, 2000);

};