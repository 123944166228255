import React, { useContext, useState } from "react";
import Profile from "./profile";
import { Form, FormControl, Button, Spinner } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { changePassword, handleLogout } from "../../utils/Api.js";
import UserContext from "../../context/UserContext";

const Settings = () => {

  const { user, setUser } = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  const [passwordHide, setpasswordHide] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const changePasswordSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required("Current password is required")
      .matches(
        /^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
        "Password must be alpa numeric"
      ),
    newPassword: yup
      .string()
      .min(8, "New password must be at least 8 characters")
      .matches(
        /^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
        "Password must be alpa numeric"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required")
      .matches(
        /^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
        "Password must be alpa numeric"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

 

  const handleSettings = async (values) => {
    setLoader(true);
    const response = await changePassword(values);
    if (response.code === 200) {
      setLoader(false);
      toast.success(response.message);
      await handleLogout(user,setUser);
    } else {
      setLoader(false);
      toast.error(response.message, { toastId: "error-reset-password" });
    }
  };

  const handleChangePassword = (type) => {
    setpasswordHide((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  return (
    <Profile>
      <div className='settings-page'>
        <div className='page-heading'>
          <h3>Settings</h3>
        </div>

        <div className='settings-content '>
          <Form onSubmit={handleSubmit(handleSettings)}>
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>Current Password</Form.Label>
              <div className='inp-section'>
                <FormControl
                  type={
                    passwordHide.currentPassword === true ? "text" : "password"
                  }
                  autoComplete='new-password'
                  placeholder='Enter current password'
                  {...register("currentPassword")}
                />
                <span onClick={() => handleChangePassword("currentPassword")}>
                  {passwordHide.currentPassword === true && (
                    <i className='fa fa-eye' aria-hidden='true'></i>
                  )}
                  {passwordHide.currentPassword === false && (
                    <i className='fa fa-eye-slash' aria-hidden='true'></i>
                  )}
                </span>
              </div>
              {!!errors?.currentPassword && (
                <p className='error-text'>{errors?.currentPassword.message}</p>
              )}
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>New Password</Form.Label>
              <div className='inp-section'>
                <FormControl
                  type={passwordHide.newPassword === true ? "text" : "password"}
                  placeholder='Enter new password'
                  autoComplete='new-password'
                  {...register("newPassword")}
                />
                <span onClick={() => handleChangePassword("newPassword")}>
                  {passwordHide.newPassword === true && (
                    <i className='fa fa-eye' aria-hidden='true'></i>
                  )}
                  {passwordHide.newPassword === false && (
                    <i className='fa fa-eye-slash' aria-hidden='true'></i>
                  )}
                </span>
              </div>
              {!!errors?.newPassword && (
                <p className='error-text'>{errors?.newPassword.message}</p>
              )}
            </Form.Group>
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>Confirm New Password</Form.Label>
              <div className='inp-section'>
                <FormControl
                  type={
                   "password"
                  }
                  placeholder='Enter confirm new password'
                  autoComplete='new-password'
                  {...register("confirmPassword")}
                />
                <span >
                  {/* {passwordHide.confirmPassword === true && ( */}
                    <i className='fa fa-eye' aria-hidden='true'></i>
                  {/* )} */}
                  {/* {passwordHide.confirmPassword === false && (
                    <i className='fa fa-eye-slash' aria-hidden='true'></i>
                  )} */}
                </span>
              </div>
              {!!errors?.confirmPassword && (
                <p className='error-text'>{errors?.confirmPassword.message}</p>
              )}
            </Form.Group>

            <div className='setting-btn'>
              <Button className='save-btn' type='submit' disabled={loader}>
                {loader === true ? (
                  <Spinner animation='border' className='accept-btn-show-btn' />
                ) : (
                  <span>SAVE CHANGES</span>
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Profile>
  );
};

export default Settings;
