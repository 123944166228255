import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Layout from "../layout/layout";
import userimg from "../../assets/images/home/user.png";
import logout from "../../assets/images/profile/logout.svg";
import setting from "../../assets/images/profile/setting.svg";
import historyImg from "../../assets/images/profile/history.svg";
import transaction from "../../assets/images/profile/transaction.svg";
import "./profile.css";
import UserContext from "../../context/UserContext";
import { handleLogout } from "../../utils/Api";

const Profile = ({ children }) => {
  const { user, setUser } = useContext(UserContext);
  const [closeProfileSidebar, setCloseProfile] = useState(false);
  const handleCloseSidebar = () => {
    if(closeProfileSidebar===true){
      setCloseProfile(false);
    }
    else {
      setCloseProfile(true);
    }
    
  };
  const profileRef = useRef();
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCloseProfile(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(profileRef);

  return (
    <>
      <Layout>
        <div className="profile-wrapper">
          <div className="container">
            <div className="profile-grid">
              <div className="profile-sidebar">
                <div className="profile-user">
                  <div className="user-info">
                    <div className="user-name">
                      <img src={user?.avatar ? user?.avatar : userimg} alt="" />{" "}
                      {user?.firstName}
                    </div>
                  </div>
                </div>
                <div className="profile-menu">
                  <h5>Menu</h5>
                  <Nav>
                    <NavLink to="/profile">
                      <i className="las la-user-tie"></i>Profile
                    </NavLink>
                    <NavLink to="/settings">
                      <i className="las la-cog"></i>Settings
                    </NavLink>
                    <NavLink to="/bethistory">
                      <i className="las la-history"></i>Bet History
                    </NavLink>
                    <NavLink to="/transactions">
                      <i className="las la-hand-holding-usd"></i>Transactions
                    </NavLink>
                    <Button
                      onClick={() => handleLogout(user, setUser)}
                      className="logout"
                    >
                      <i className="las la-sign-out-alt"></i>Logout
                    </Button>
                  </Nav>
                </div>
              </div>
              <div className="profile-content">
                <div className="btn-span">
                  <span
                    onClick={handleCloseSidebar}
                    className="envelop-btn"
                    role="presentation"
                  >
                    {!closeProfileSidebar ? (
                      <i className="bi bi-list"></i>
                    ) : (
                      <i className="bi bi-x-circle"></i>
                    )}
                  </span>
                </div>
                {children}
              </div>
            </div>
          </div>
        {/* </div> */}
        <div
          className={`profile-sidebar1   ${
            !closeProfileSidebar ? "sidebar-hide" : ""
          } `}
          ref={profileRef}
        >
          <div className="profile-menu">
            <div className="user-info">
              <div className="user-name">
                {/* <img src={user?.avatar ?? userimg} alt="img-avtar" /> {user?.firstName} */}
                <img src={user?.avatar ? user?.avatar : userimg} alt="" />{" "}
                {user?.firstName}
              </div>
            </div>
            <h5>Menu</h5>
            <Nav>
              <NavLink to="/profile">
                {/* <img src={userimg} alt="" />
              Profile */}
                <i className="bi bi-person-circle profile_icon"></i> Profile
              </NavLink>
              <NavLink to="/settings">
                <img src={setting} alt="" /> Settings
              </NavLink>
              <NavLink to="/bethistory">
                <img src={historyImg} alt="" /> Bet History
              </NavLink>
              <NavLink to="/transactions">
                <img src={transaction} alt="" /> Transactions
              </NavLink>
              {/* <NavLink to="" className="logout">
              <img src={logout} alt="" /> Logout
            </NavLink> */}
              <Button
                onClick={() => handleLogout(user, setUser)}
                className="logout"
              >
                <img src={logout} alt="" /> Logout
              </Button>
            </Nav>
          </div>
        </div>
        </div>
      </Layout>
    </>
  );
};

export default Profile;
