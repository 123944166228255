import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/home/home";
import Roulette from "./components/games/roulette/roulette";
import Dice from "./components/games/dice/dice";
import Settings from "./components/profile/settings";
import Fairness from "./components/profile/fairness";
import Transaction from "./components/profile/transaction";
import BetHistory from "./components/profile/bethistory";
import Overview from "./components/verifygames/overview";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modals from "./components/modals/modals";
import FaqPage from "./components/faq/faq";
import Mines from "./components/games/mines/mines";
import SlotGames from "./components/games/slots/allslot";
import Tower from "./components/games/tower/tower";
import Limbo from "./components/games/limbo/index";
import LiveGames from "./components/cominsoon/livegames";
import Monopoly from "./components/cominsoon/monopoly";
import CrazyTime from "./components/cominsoon/crazytime";
import Slot from "./components/games/slot";
import Plinko from "./components/games/plinko";
// import Crash from "./components/games/crash";
import UserContext from "./context/UserContext";
import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-input-2/lib/style.css";
import "./responsive.css";
import { authInstance, userInstance } from "./config/axios";
import "./App.css";
import socket from "./config/socket";
import ProfilePage from "./components/profile/my-profile";
import Privacy from "./components/privacyPolicy/Privacy";
import TermAndServices from "./components/term-and-services/TermAndServices";
import VerifyEmail from "./components/register/verify-email";
import EmailMessageShow from "./components/register/email-notificaton";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { getCookie } from "./utils/cookies";

function PrivateRoute(props) {
  let { component: Component, children, render, ...rest } = props;
  const { user } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={() => {
        if (user) return <Component />;
        else if (!user) return <Redirect to="/" />;
      }}
    />
  );
}

const App = () => {
  const [loader, setLoader] = useState(true);
  const [user, setUser] = useState();
  const [token, setToken] = useState(getCookie("token"));
  const [resetModal, setResetModal] = useState(false);
  const [isCheckEmailVerification, setIsCheckEmailVerification] =
    useState(false);
  const [storeEmailForReverification, setStoreEmailForReverification] =
    useState("");

  const getProfile = async () => {
    setLoader(true);
    try {
      const res = await userInstance.get("");
      const { data } = res;
      if (data) {
        setUser(data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!user) {
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    socket.on("getUpdatedBalance", (data) => {
      const userid = getCookie("userid");
      const id = data?._id ? data?._id : data?.id;
      if (userid === id) {
        setUser(data);
      }
    });
  }, []);

  const isValidUrl = async (values) => {
    try {
      await authInstance.post(`/check-isValid`, values);
      if (!window.location.search.includes("undefine")) {
        setResetModal(true);
      } else {
        setResetModal(false);
      }
    } catch (error) {
      if (error?.response?.data) {
        setResetModal(false);
        setTimeout(() => {
          toast.error("Link has been expired", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }, 3000);
        window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    if (window.location.href.includes("?reset-password-token=")) {
      isValidUrl({
        token: window?.location?.search
          ?.split("?reset-password-token=")
          .join(""),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebar = () => {
    if (openSidebar === true) {
      localStorage.removeItem("sidebar");
    }
  };

  return (
    <div className="App">
      <div className="main-wrapper">
        <React.Fragment>
          <UserContext.Provider
            value={{
              token,
              setToken,
              user,
              setUser,
              resetModal,
              setResetModal,
              isCheckEmailVerification,
              setIsCheckEmailVerification,
              setStoreEmailForReverification,
              storeEmailForReverification,
              loader,
              setLoader,
              openSidebar,
              setOpenSidebar,
              handleSidebar,
            }}
          >
            <Router>
              <main>
                <div className="main_content">
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Home />}
                      socket={socket}
                    />
                    <Route path="/slot" render={() => <Slot />} />
                    <Route path="/limbo" render={() => <Limbo />} />
                    {/* <Route path="/crash" render={() => <Crash />} /> */}
                    <Route path="/plinko" render={() => <Plinko />} />
                    <Route path="/livegames" render={() => <LiveGames />} />
                    <Route path="/monopoly" render={() => <Monopoly />} />
                    <Route path="/crazytime" render={() => <CrazyTime />} />
                    <Route path="/limboGame" render={() => <Limbo />} />
                    <Route path="/roulette" render={() => <Roulette />} />
                    <Route path="/slots" render={() => <SlotGames />} />
                    <Route path="/dice" render={() => <Dice />} />
                    <Route path="/tower" render={() => <Tower />} />
                    <Route path="/mines" render={() => <Mines />} />
                    <Route path="/fairness" render={() => <Fairness />} />
                    <Route path="/overview" render={() => <Overview />} />
                    <PrivateRoute path="/profile" component={ProfilePage} />
                    <PrivateRoute path="/settings" component={Settings} />
                    <PrivateRoute path="/bethistory" component={BetHistory} />
                    <PrivateRoute
                      path="/transactions"
                      component={Transaction}
                    />
                    <Route path="/Privacy" render={() => <Privacy />} />
                    <Route
                      path="/term-and-services"
                      render={() => <TermAndServices />}
                    />
                    <Route path="/tip" render={() => <Modals />} />
                    <Route path="/faq" render={() => <FaqPage />} />
                    <Route path="/livegames" render={() => <LiveGames />} />
                    <Route
                      exact
                      path="/verify-email"
                      render={() => <VerifyEmail />}
                    />
                    <Route
                      exact
                      path="/verify-notification"
                      render={() => <EmailMessageShow />}
                    />
                  </Switch>
                </div>
              </main>
            </Router>
            <ToastContainer />
          </UserContext.Provider>
        </React.Fragment>
      </div>
    </div>
  );
};

export default App;
