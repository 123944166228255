import React from 'react';

const ChatContent = ({handleTip}) => {
    return (
        <div className='chat-content-popup'>
            <div className='chat-content-details'>
                <ul>
                    <li onClick={handleTip}>
                        <h6>/tip @user<span>Tip a user</span></h6>
                    </li>
                    <li>
                        <h6>/mention @user<span>Mention a user</span></h6>
                    </li>
                    <li>
                        <h6>/delete<span>Deletes a message</span></h6>
                    </li>
                    <li>
                        <h6>/mute @user time<span>Mutes a user</span></h6>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default ChatContent