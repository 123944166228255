import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./register.css";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { authInstance } from "../../config/axios";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  RegitrationSchema,
} from "../../validationSchema/authSchema";
import { yupResolver } from "@hookform/resolvers/yup";

const Register = ({ handleModal }) => {
  const [pass, setPass] = useState("password");
  const [confpass, setConfPass] = useState("password");
  const [loader, setLoader] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
     setValue,
     getValues
} = useForm({ mode: "onBlur", resolver: yupResolver(RegitrationSchema) });

  const registerUser = async (data) => {
    setLoader(true);
    try {
        const resp = await authInstance.post(
          "/register",
          {
            email: data?.email,
            password: data?.password,
            verifyPassword: data?.verifyPassword,
            firstName: data?.firstName,
            lastName: data?.lastName,
            phoneNumber: data?.phoneNumber,
            gender: data?.select,
          },
          { withCredentials: true, credentials: "include" }
        );
        if (resp.data.msg === "Email send for verification") {
          window.location.href = "/verify-notification";
        }
        setLoader(false);
        handleModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.message, { id: "register" });
      setLoader(false);
    }
  };

  const handlePass = () => {
    setPass(!pass);
  };
  const handlePass1 = () => {
    setConfPass(!confpass);
  };

  const handlePhoneNumber=(phone)=>{
    setValue('phoneNumber',phone);
  }

  return (
    <div className="signup">
      <div className="register-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="register-form">
                <h1>Sign Up</h1>
                <p>Welcome, Please fill your detail to create your account</p>
                <div className="rigister-tab">
                  <Form onSubmit={handleSubmit(registerUser)}>
                    <div className="one-row">
                      <Form.Group controlId="formBasicFirst">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter first name"
                          name="firstName"
                          {...register("firstName", {
                            required: "First name is required",
                          })}
                        />
                        {errors.firstName &&<p className='error-msg'>{errors.firstName.message}</p>}
                      </Form.Group>
                      <Form.Group controlId="formBasicFirst">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Last name"
                          name="lastName"
                          {...register("lastName", {
                          })}
                          {...register("lastName", {
                            required: "Enter First Name",
                          })}
                        />
                        { errors.lastName && <p className='error-msg'>{errors.lastName.message}</p> }
                      </Form.Group>
                    </div>
                    <div className="one-row">
                      <Form.Group controlId="formBasicFirst">
                        <Form.Label>Phone no.</Form.Label>
                        <PhoneInput
                          country="us"
                          value={getValues('phoneNumber')}
                          onChange={(phone)=>handlePhoneNumber(phone)}
                        />
                        {errors.phoneNumber && <p className='error-msg'>{errors.phoneNumber.message}</p>}
                      </Form.Group>
                      <Form.Group controlId="formBasicSelect">
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                          as="select"
                          name="select"

                          {...register("select")}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <Form.Group controlId="formBasicFirst">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your email"
                        name="email"
                        autoComplete="off"
                        {...register("email", {
                          required: "Enter Valid Email",
                        })}
                      />
                      {errors.email && <Form.Label className='error-msg'>{errors.email.message}</Form.Label>}
                    </Form.Group>
                    <div className="one-row">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type={pass ? "password" : "text"}
                          placeholder="......."
                          name="password"
                          autoComplete="off"
                          {...register("password", {
                            required: "password is required",
                          })}
                        />
                        <span className="input-icon1" onClick={handlePass}>
                          <i
                            className={pass ? "las la-eye" : "las la-eye-slash"}
                          ></i>
                        </span>
                       {errors.password && <p className='error-msg'>{errors.password.message}</p>}
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type={confpass ? "password" : "text"}
                          placeholder="........."
                          name="password"
                          autoComplete="off"
                          {...register("verifyPassword", {
                            required: "please enter correct password",
                          })}
                        />

                        <span className="input-icon1" onClick={handlePass1}>
                          <i
                            className={confpass ? "las la-eye" : "las la-eye-slash"}
                          ></i>
                        </span>

                        {errors.verifyPassword && <p className='error-msg'>{errors.verifyPassword.message}</p>}
                      </Form.Group>
                    </div>
                    <div className="text-center">
                      <Button className="s-btn register-btn" type="submit" disabled={loader}>
                        {loader ? (
                          <Spinner animation="border" />
                        ) : (
                          "Create Account"
                        )}
                      </Button>
                    </div>
                    <div className="social-login">
                      <span>OR</span>
                      <div className="social-btns">
                        <Button className="twitch-btn">
                          <i className="fa fa-steam" /> Login with Steam
                        </Button>
                      </div>
                    </div>
                  </Form>
                  <p className="already">
                    Already have an account?{" "}
                    <Button
                      className="forget-link"
                      onClick={() => handleModal("login")}
                    >
                      Login
                    </Button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
