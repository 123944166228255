import React from "react";
import logo from "../../assets/images/header/logo.png";

import "./register.css";

// import { Spinner } from "react-bootstrap";

const EmailMessageShow = () => {
    const handleClick = () => {
        window.location.href = "/";
    };
    return (
        <div className="signup">
            <div className="register-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="email-notification" onClick={handleClick}>

                                <img src={logo} alt="logo" />

                                <div><h1><span>You are registered,</span></h1> <h4>please check your email to verify!</h4></div>
                                {/* <p>You are registered, please check your email to verify!</p> */}
                               <span className="btn">
                                OK
                               </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailMessageShow;
