import React from "react";
import { Button, Form, Spinner } from 'react-bootstrap';
import "./forget.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ResetPasswordSchema } from "../../validationSchema/authSchema";
import { forgetPassword } from "../../utils/Api";
import { useState } from "react";
// import { toast } from "react-toastify";
// import { Button, Modal, Spinner } from "react-bootstrap";

const Forget = () => {

    const [isResetPass, setIsResetPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resError, setResError] = useState("");
    const {
        register,
        handleSubmit,
        formState: { errors },
        // reset,
        // setValue,
    } = useForm({ mode: "onBlur", resolver: yupResolver(ResetPasswordSchema) });

    const handleResetPassword = async (values) => {
        setLoading(true);
        const res = await forgetPassword(values);
        if (res?.statusText === "No Content") {
            setIsResetPass(true);
            setResError("")
        }
        if (res?.message === "No users found with this email") {
            setResError(res?.message)
        }
        setLoading(false)

    };
    return (
        <div className="forget-password-section">
            <div className="forget-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {isResetPass ? (<div className="forget-form">
                                <h1>Please check your email to reset your password!</h1>
                                {/* <p> Please check your email to reset your password!</p> */}

                            </div>) : (<div className="forget-form">
                                <h1>Forget your <span>Password?</span></h1>
                                <p>Enter your email to reset your password</p>
                                <div className="forget-box">
                                    <Form onSubmit={handleSubmit(handleResetPassword)}>
                                        <Form.Group controlId="formBasicloginone">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your email"
                                                name="email"
                                                autoComplete="off"
                                                {...register("email", {
                                                    required: "Enter Valid Email",
                                                })}
                                            />
                                            {errors.email && (
                                                <p className="error-msg">
                                                    {errors.email.message}
                                                </p>
                                            )}
                                            {resError && (
                                                <p className="error-msg">
                                                    {resError}
                                                </p>
                                            )}
                                        </Form.Group>
                                        <div className="login-button">
                                            <Button className="l-btn" type='submit'>
                                                {loading ? (
                                                    <Spinner size="sm"
                                                        animation='border' className="accept-btn-show-btn"

                                                    />
                                                ) : (
                                                    "Continue"
                                                )}

                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>)}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Forget;