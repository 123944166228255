import React from "react";
import Layout from "../layout/layout";
import "./termandservices.css";
const TermAndServices = () => {
  return (
    <Layout>
      <div className="term-services">
        <h1 className="heading">Terms and Conditions</h1>
        <h1>1. winit.com</h1>
        <p>
          winit.com is owned and operated by Medium Rare, N.V. (hereinafter
          "Winit", "We" or "Us"), a company with head office at Fransche
          Bloemweg 4 Curaçao. Medium Rare is licensed and regulated by the
          Government of Curaçao under the gaming license 8048/JAZ issued to
          Antillephone. Some credit card payment processing are handled by its
          wholly owned subsidiary, Medium Rare Limited.
        </p>
        <h1>2. General</h1>
        <p>
          This end user agreement (the "Agreement") should be read by you (the
          "User" or "you") in its entirety prior to your use of Winit's service
          or products. Please note that the Agreement constitutes a legally
          binding agreement between you and Winit.
        </p>
        <p>
          {" "}
          These Terms and Conditions come into force as soon as you complete the
          registration process, which includes checking the box accepting these
          Terms and Conditions and successfully creating an account. By using
          any part of the Website following account creation, you agree to these
          Terms and Conditions applying to the use of the Website.
        </p>
        <p>
          {" "}
          You must read these Terms and Conditions carefully in their entirety
          before creating an account. If you do not agree with any provision of
          these Terms and Conditions, you must not create an account or continue
          to use the Website.
        </p>
        <p>
          {" "}
          We are entitled to make amendments to these Terms and Conditions at
          any time and without advanced notice. If we make such amendments, we
          may take appropriate steps to bring such changes to your attention
          (such as by email or placing a notice on a prominent position on the
          Website, together with the amended terms and conditions) but it shall
          be your sole responsibility to check for any amendments, updates
          and/or modifications. Your continued use of winit.com services and
          Website after any such amendment to the Terms and Conditions will be
          deemed as your acceptance and agreement to be bound by such
          amendments, updates and/or modifications.
        </p>
        <p>
          {" "}
          By clicking the "I Agree" button if and where provided and/or using
          the Service, you consent to the terms and conditions set forth in this
          Agreement.
        </p>
        <p>
          {" "}
          These Terms and Conditions may be published in several languages for
          informational purposes and ease of access by players. The English
          version is the only legal basis of the relationship between you and us
          and in the case of any discrepancy with respect to a translation of
          any kind, the English version of these Terms and Conditions shall
          prevail.
        </p>
        <h1>3. Winit Account - Registration</h1>
        <p>
          In order for you to be able to place bets on winit.com, you must first
          personally register an account with us ("Winit Account").
        </p>
        <p>
          {" "}
          As part of the registration process, you will have to provide your
          date of birth, valid e-mail and choose a username and password for
          your login into winit.com. You must enter all mandatory information
          requested into the registration form.
        </p>
        <p>
          {" "}
          It is your sole responsibility to ensure that the information you
          provide is true, complete and correct. You will have to choose a
          username which is not disruptive or offensive.
        </p>
        <p>
          {" "}
          It is your sole and exclusive responsibility to ensure that your login
          details are kept securely. You must not disclose your login details to
          anyone.
        </p>
        <p>
          We are not liable or responsible for any abuse or misuse of your Winit
          Account by third parties due to your disclosure, whether intentional,
          accidental, active or passive, of your login details to any third
          party. You are allowed to have only one Winit Account.
        </p>
        <p>
          {" "}
          If you attempt to open more than one Winit Account, all of your
          accounts may be blocked, suspended or closed and any crypto amount
          credited to your account/s will be frozen. If you notice that you have
          more than one registered Winit Account you must notify us immediately.
        </p>
        <p>
          {" "}
          Failure to do so may lead to your Winit Account being blocked. You
          will inform us as soon as you become aware of any errors with respect
          to your account or any calculations with respect to any bet you have
          placed.
        </p>
        <p>
          {" "}
          We reserve the right to declare null and void any bets that are
          subject to such an error.
        </p>{" "}
        <h1> 4. Know your Customer ("KYC")</h1>
        <p>
          {" "}
          Winit reserves the right, at any time, to ask for any KYC
          documentation it deems necessary to determine the identity and
          location of a User. Winit reserves the right to restrict the Service,
          payment or withdrawal until identity is sufficiently determined, or
          for any other reason in Winit's sole discretion. Winit also reserves
          the right to disclose a User's information as appropriate to comply
          with legal process or as otherwise permitted by the privacy policy of
          Winit (owner and operator of Winit), and by using the Service, you
          acknowledge and consent to the possibility of such disclosure.
        </p>
        <h1>5. Your Warranties</h1>
        <ul>
          <li>
            Prior to your use of the Service and on an ongoing basis you
            represent, warrant, covenant and agree that: You are over 18 or such
            other legal age of majority as determined by any laws which are
            applicable to you, whichever age is greater;
          </li>
          <li>
            {" "}
            You have full capacity to enter into a legally binding agreement
            with us and you are not restricted by any form of limited legal
            capacity;
          </li>
          <li>
            {" "}
            You are not diagnosed or classified as a compulsive or problem
            gambler;
          </li>
          <li>
            {" "}
            You are accessing winit.com from a jurisdiction in which it is legal
            to do so;
          </li>
          <li>
            {" "}
            All information that you provide to us during the term of validity
            of this agreement is true, complete, correct, and that you shall
            immediately notify us of any change of such information;
          </li>
          <li>
            {" "}
            You will not use our services while located in any jurisdiction that
            prohibits the placing and/or accepting of bets online and/or playing
            casino and/or live games;
          </li>
          <li>
            {" "}
            You are not currently self-excluded from any gambling site or
            gambling premises.
          </li>
          <li>
            {" "}
            You will inform Winit immediately if you enter into a self-exclusion
            agreement with any gambling provider.
          </li>
          <li>
            {" "}
            Your use of the Service is at your sole option, discretion and risk;
          </li>
          <li>
            {" "}
            You participate in the Games strictly in your personal and
            non-professional capacity and participate for recreational and
            entertainment purposes only;
          </li>
          <li>
            {" "}
            You participate in the Games on your own behalf and not on the
            behalf of any other person;
          </li>
          <li>
            {" "}
            You are solely responsible for reporting and accounting for any
            taxes applicable to you under relevant laws for any winnings that
            you receive from us;
          </li>{" "}
          <li>
            You are solely responsible for any applicable taxes which may be
            payable on cryptocurrency awarded to you through your using the
            Service; There is a risk of losing cryptocurrency when using the
            Service and that Winit has no responsibility to you for any such
            loss;
          </li>
          <li>
            {" "}
            In relation to deposits and withdrawals of funds into and from your
            Winit Account, you shall only use crypto that is valid and lawfully
            belongs to you;{" "}
          </li>
          <li>
            You accept and acknowledge that the value of cryptocurrency can
            change dramatically depending on the market value; You have only one
            account with us and agree to not to open any more accounts with us;
          </li>
          <li>
            {" "}
            You accept and acknowledge that we reserve the right to detect and
            prevent the use of prohibited techniques, including but not limited
            to fraudulent transaction detection, automated registration and
            signup, gameplay and screen capture techniques. These steps may
            include, but are not limited to, examination of Players device
            properties, detection of geo-location and IP masking, transactions
            and blockchain analysis;
          </li>
          <li>
            {" "}
            The telecommunications networks and Internet access services
            required for you to access and use the Service are entirely beyond
            the control of Winit and Winit shall have no liability whatsoever
            for any outages, slowness, capacity constraints or other
            deficiencies affecting the same;
          </li>
        </ul>
        <h1>6. Winit Warranties</h1>
        <p>
          Winit disclaims any and all warranties, expressed or implied, in
          connection with the service which is provided to you "as is" and we
          provide you with no warranty or representation whatsoever regarding
          its quality, fitness for purpose, completeness or accuracy.
        </p>
        <p>
          Regardless of Winit's efforts, Winit makes no warranty that the
          service will be uninterrupted, timely or error-free, or that defects
          will be corrected.
        </p>{" "}
        <h1>7. Deposits</h1>
        <p>
          You may participate in any Game only if you have sufficient funds on
          your Winit Account for such participation. For that purpose you shall
          use the payment methods available on winit.com to deposit your funds.
          Winit will not give you any credit whatsoever for participation in any
          Game.
        </p>
        <p>
          To deposit funds into your Winit Account, you can transfer funds from
          crypto-wallets under your control or through any other payment methods
          available on winit.com . Deposits can only be made with your own
          funds.
        </p>
        <p>
          Deposits are immediately processed and the updated balance is shown in
          the Winit Account instantly whenever a payment service provider is
          used. Winit does not take responsibility for any delays caused due to
          its payment system or due to delays caused by any third party.
        </p>
        <p>
          We reserve the right to use additional procedures and means to verify
          your identity when processing deposits into your Winit Account.
        </p>
        <p>
          Note that some payment methods may include additional fees. In this
          case, the fee will be clearly visible for you in the cashier.
        </p>
        <p>
          Note that your bank or payment service provider may charge you
          additional fees for deposits of currency conversion according to their
          terms and conditions and your user agreement.
        </p>
        <p>
          Funds cannot be transferred from your Winit Account to the Winit
          Account of another Player. Winit can refuse any deposits at its own
          description. Users that have their accounts blocked, or suspended
          shall refrain from depositing at www.winit.com . In the event that a
          player tries to deposit when his account is blocked or suspended,
          Winit will have the right to retain the funds.
        </p>
        <h1>8. Withdrawals</h1>
        <p>
          Crypto withdrawals will be made to your stated cryptocurrency wallet
          address when making a valid withdrawal request.
        </p>
        <p>
          The player needs to wager 100% of the value of their deposit in order
          to request a FIAT withdrawal.
        </p>
        <p>
          If we miWinitnly credit your Winit Account with winnings that do not
          belong to you, whether due to a technical error in the pay-tables, or
          human error or otherwise, the amount will remain our property and will
          be deducted from your Winit Account. If you have withdrawn funds that
          do not belong to you prior to us becoming aware of the error, the
          miWinitnly paid amount will (without prejudice to other remedies and
          actions that may be available at law) constitute a debt owed by you to
          us. In the event of an incorrect crediting, you are obliged to notify
          us immediately by email.
        </p>
        <p>
          Winit reserves the right to carry out additional KYC verification
          procedures for any withdrawal. Account Holders who wish to recover
          funds held in a closed, locked or excluded account, are advised to
          contact Customer Support.
        </p>
        <p>
          All transactions shall be checked in order to prevent money
          laundering. If a player becomes aware of any suspicious activity
          relating to any of the Games of the Website, s/he must report this to
          Winit immediately. Winit may suspend, block or close a Winit Account
          and withhold funds if requested to do so in accordance with the
          Prevention of Money Laundering Act or on any other legal basis
          requested by any state authority.
        </p>
        <p>
          You acknowledge that the funds in your account are consumed instantly
          when playing and we do not provide return of goods, refunds or
          retrospective cancellation of your account.
        </p>
        <p>
          Note that some payment methods may include an additional fee. In this
          case, the fee will be clearly visible for you in the cashier.
        </p>
        <h1>9. Bonus</h1>
        <p>
          Winit, at its sole discretion, might offer from time to time, a number
          of Bonuses and Promotions. For example, The Million Dollar Race, the
          50 Billionth Bet Bonanza, Coupons, Reloads, and Rakeback.
        </p>
        <p>
          Each of the Bonuses and Promotions has its own set of terms and
          conditions and individual requirements if any. By activating a Bonus
          or Promotion, You confirm that You also agree to the applicable terms
          and conditions.
        </p>
        <p>
          Once forfeited or de-activated, the Bonus will no longer be available
          to You (and cannot be re-activated at any time thereafter). The amount
          of any Bonus Funds that have already been credited to your Winit
          Account Balance will remain available to You.
        </p>
        <p>
          Winit reserves the right to remove bonus from all inactive accounts or
          accounts that are identified as “bonus abusers”.
        </p>
        <p>
          Winit reserves the right to cancel all bonuses that have not been
          claimed within the claiming period or 60 days, the shorter of the two.
        </p>
        <p>
          Winit reserves the right to cancel any bonus at its sole description.
        </p>
        <h1>10. Authority/Terms of Service</h1>
        <p>
          You agree to the game rules described on the winit.com website. Winit
          retains authority over the issuing, maintenance, and closing of the
          Service. The decision of Winit's management, concerning any use of the
          Service, or dispute resolution, is final and shall not be open to
          review or appeal.
        </p>
        <h1>11. Prohibited Uses</h1>
        <p>
          PERSONAL USE. The Service is intended solely for the User's personal
          use. The User is only allowed to wager for his/her personal
          entertainment. Users may not create multiple accounts for the purpose
          of collusion, sports betting and/or abuse of service.
        </p>
        <p>
          AML & SANCTIONS COMPLIANCE. Winit expressly prohibits and rejects the
          use of the Service for any form of illicit activity, including money
          laundering, terrorist financing or trade sanctions violations,
          consistent with various jurisdictions' laws, regulations and norms. To
          that end, the Service is not offered to individuals or entities
          subject to United States, European Union, or other global sanctions or
          watch lists. By using the Service, you represent and warrant that you
          are not so subject.
        </p>
        <p>
          JURISDICTIONS. Persons located in or reside in Afghanistan, Australia,
          Belarus, Belgium, Côte d'Ivoire, Cuba, Curaçao, Czech Republic,
          Denmark, Democratic Republic of the Congo, France, Germany, Greece,
          Iran, Iraq, Italy, Liberia, Libya, Lithuania, Netherlands, North
          Korea, Ontario, Portugal, Serbia, Slovakia, South Sudan, Spain, Sudan,
          Syria, Switzerland, United Kingdom, United States, Zimbabwe (the
          "Prohibited Jurisdictions") are not permitted make use of the Service.
          For the avoidance of doubt, the foregoing restrictions on engaging in
          real-money play from Prohibited Jurisdictions applies equally to
          residents and citizens of other nations while located in a Prohibited
          Jurisdiction. Any attempt to circumvent the restrictions on play by
          any persons located in a Prohibited Jurisdiction or Restricted
          Jurisdiction, is a breach of this Agreement. An attempt at
          circumvention includes, but is not limited to, manipulating the
          information used by Winit to identify your location and providing
          Winit with false or misleading information regarding your location or
          place of residence.
        </p>
        <p>
          The attempt to manipulate your real location through the use of VPN,
          proxy, or similar services or through the provision of incorrect or
          misleading information about your place of residence, with the intent
          to circumvent geo-blocking or jurisdiction restrictions, constitutes a
          breach of Clause 5 of this Terms of Service.
        </p>
        <h1>12. Breach</h1>
        <p>
          Without prejudice to any other rights, if a User breaches in whole or
          in part any provision contained herein, Winit reserves the right to
          take such action as it sees fit, including terminating this Agreement
          or any other agreement in place with the User and/or taking legal
          action against such User.
        </p>
        <p>
          You agree to fully indemnify, defend and hold harmless Winit and its
          shareholders, directors, agents and employees from and against all
          claims, demands, liabilities, damages, losses, costs and expenses,
          including legal fees and any other charges whatsoever, howsoever
          caused, that may arise as a result of: (i) your breach of this
          Agreement, in whole or in part; (ii) violation by you of any law or
          any third party rights; and (iii) use by you of the Service.
        </p>
        <h1>13. Limitations and Liability</h1>
        <p>
          Under no circumstances, including negligence, shall Winit be liable
          for any special, incidental, direct, indirect or consequential damages
          whatsoever (including, without limitation, damages for loss of
          business profits, business interruption, loss of business information,
          or any other pecuniary loss) arising out of the use (or misuse) of the
          Service even if Winit had prior knowledge of the possibility of such
          damages.
        </p>
        <p>
          Nothing in this Agreement shall exclude or limit Winit's liability for
          death or personal injury resulting from its negligence.
        </p>
        <h1>14. Intellectual Property</h1>
        <p>
          Winit and its licensors are the sole holders of all rights in and to
          the Service and code, structure and organization, including copyright,
          trade secrets, intellectual property and other rights. You may not,
          within the limits prescribed by applicable laws: (a) copy, distribute,
          publish, reverse engineer, decompile, disassemble, modify, or
          translate the website; or (b) use the Service in a manner prohibited
          by applicable laws or regulations (each of the above is an
          "Unauthorized Use"). Winit reserves any and all rights implied or
          otherwise, which are not expressly granted to the User hereunder and
          retain all rights, title and interest in and to the Service. You agree
          that you will be solely liable for any damage, costs or expenses
          arising out of or in connection with the commission by you of any
          Unauthorized Use. You shall notify Winit immediately upon becoming
          aware of the commission by any person of any Unauthorized Use and
          shall provide Winit with reasonable assistance with any investigations
          it conducts in light of the information provided by you in this
          respect.
        </p>
        <p>
          The term "Winit", its domain names and any other trade marks, or
          service marks used by Winit as part of the Service (the "Trade
          Marks"), are solely owned by Winit. In addition, all content on the
          website, including, but not limited to, the images, pictures,
          graphics, photographs, animations, videos, music, audio and text (the
          "Site Content") belongs to Winit and is protected by copyright and/or
          other intellectual property or other rights. You hereby acknowledge
          that by using the Service, you obtain no rights in the Site Content
          and/or the Trade Marks, or any part thereof. Under no circumstances
          may you use the Site Content and/or the Trade Marks without Winit's
          prior written consent. Additionally, you agree not to do anything that
          will harm or potentially harm the rights, including the intellectual
          property rights of Winit.
        </p>
        <h1>15. Disputes</h1>
        <p>
          If a User wishes to make a complaint, please contact Winit's customer
          service team at support@winit.com . Should any dispute not be resolved
          to your satisfaction you may pursue remedies in the governing law
          jurisdiction set forth below.
        </p>
        <h1>16. Amendment</h1>
        <p>
          Winit reserves the right to update or modify this Agreement or any
          part thereof at any time or otherwise change the Service without
          notice and you will be bound by such amended Agreement upon posting.
          Therefore, we encourage you check the terms and conditions contained
          in the version of the Agreement in force at such time. Your continued
          use of the Service shall be deemed to attest to your agreement to any
          amendments to the Agreement.
        </p>
        <h1>17. Arbitration</h1>
        <p>
          Any claim, dispute, difference or legal action arising out of or
          relating to the Service or the Agreement shall be referred to and
          finally resolved by arbitration administered by the Hong Kong
          International Arbitration Centre (the "HKIAC") under the HKIAC
          Administered Arbitration Rules in force when the Notice of Arbitration
          is submitted. The law of this arbitration clause shall be Curaçao law.
          The seat of arbitration shall be Hong Kong. The number of arbitrators
          shall be one. The arbitration proceedings shall be conducted in
          English.
        </p>
        <h1>18. Governing Law </h1>
        <p>
          The Agreement and any matters relating hereto shall be governed by,
          and construed in accordance with, the laws of Curaçao. You irrevocably
          agree that, subject as provided below, the courts of Curaçao shall
          have exclusive jurisdiction in relation to any claim, dispute or
          difference concerning the Agreement and any matter arising therefrom
          and irrevocably waive any right that it may have to object to an
          action being brought in those courts, or to claim that the action has
          been brought in an inconvenient forum, or that those courts do not
          have jurisdiction. Nothing in this clause shall limit the right of
          Winit to take proceedings against you in any other court of competent
          jurisdiction, nor shall the taking of proceedings in any one or more
          jurisdictions preclude the taking of proceedings in any other
          jurisdictions, whether concurrently or not, to the extent permitted by
          the law of such other jurisdiction.
        </p>
        <h1>19. Severability</h1>
        <p>
          If a provision of this Agreement is or becomes illegal, invalid or
          unenforceable in any jurisdiction, that shall not affect the validity
          or enforceability in that jurisdiction of any other provision hereof
          or the validity or enforceability in other jurisdictions of that or
          any other provision hereof.
        </p>
        <h1>20. Assignment</h1>
        <p>
          Winit reserves the right to assign this agreement, in whole or in
          part, at any time without notice. The User may not assign any of
          his/her rights or obligations under this Agreement.
        </p>
        <h1>21. Miscellaneous</h1>
        <p>
          No waiver by Winit of any breach of any provision of this Agreement
          (including the failure of Winit to require strict and literal
          performance of or compliance with any provision of this Agreement)
          shall in any way be construed as a waiver of any subsequent breach of
          such provision or of any breach of any other provision of this
          Agreement.
        </p>
        <p>
          Nothing in this Agreement shall create or confer any rights or other
          benefits in favour of any third parties not party to this Agreement.
        </p>
        <p>
          Nothing in this Agreement shall create or be deemed to create a
          partnership, agency, trust arrangement, fiduciary relationship or
          joint venture between you and Winit.
        </p>
        <p>
          Winit may assign, transfer, charge, sub-license, or deal in any other
          manner with this Agreement, or sub-contract any of its rights and
          obligations under this Agreement, to any other party.
        </p>
        <p>
          This Agreement constitutes the entire understanding and agreement
          between you and Winit regarding the Service and supersedes any prior
          agreement, understanding, or arrangement between you and Winit.
        </p>
        <h1>22. Complaints</h1>
        <p>
          {" "}
          If you have a complaint to make regarding our services, you may
          contact our customer support via Live Chat or by email (
          complaints@winit.com ). We will endeavour to resolve the matter
          promptly.
        </p>
      </div>
    </Layout>
  );
};

export default TermAndServices;
