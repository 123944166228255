import React from "react";
import {
  DropdownButton,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import info from "../../../assets/images/header/info.svg";
import deadwood from "../../../assets/images/header/deadwood.svg";

const SlotGrid = ({ title, titleImg }) => {
  return (
    <>
      <div className="search-body">
        <div className="result-sec">
          <div className="all-result">
            <h6>
              <img src={titleImg} alt="" /> {title}
            </h6>
          </div>
          <div className="sort-sec">
            <DropdownButton id="dropdown-basic-button" title="Providers">
              <Dropdown.Item href="#/action-1">
                <Form.Check type="checkbox" id="" label="Evolution" />
                <span className="checkmark"></span>
              </Dropdown.Item>
              <Dropdown.Item href="#/action-1">
                <Form.Check type="checkbox" id="" label="Evolution " />
              </Dropdown.Item>
              <Dropdown.Item href="#/action-1">
                <Form.Check type="checkbox" id="" label="Evolution " />
              </Dropdown.Item>
              <Dropdown.Item href="#/action-1">
                <Form.Check type="checkbox" id="" label="Evolution " />
              </Dropdown.Item>
            </DropdownButton>
            <DropdownButton id="dropdown-basic-button" title="Providers">
              <Dropdown.Item href="#/action-1">
                <Form.Check type="checkbox" id="" label="Evolution" />
              </Dropdown.Item>
              <Dropdown.Item href="#/action-1">
                <Form.Check type="checkbox" id="" label="Evolution " />
              </Dropdown.Item>
              <Dropdown.Item href="#/action-1">
                <Form.Check type="checkbox" id="" label="Evolution " />
              </Dropdown.Item>
              <Dropdown.Item href="#/action-1">
                <Form.Check type="checkbox" id="" label="Evolution " />
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>

        <div className="all-result-sec">
          <div className="result-box">
            <div className="result-sub-box">
              <img src={deadwood} alt="" />
            </div>
            <div className="info-box">
              <div className="info-heading">
                <h6>Deadwood</h6>
                <p>No Limit City</p>
              </div>
              <div className="info-icon">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                  }
                >
                  <img src={info} alt="info" />
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="result-box">
            <div className="result-sub-box">
              <img src={deadwood} alt="" />
            </div>
            <div className="info-box">
              <div className="info-heading">
                <h6>Deadwood</h6>
                <p>No Limit City</p>
              </div>
              <div className="info-icon">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                  }
                >
                  <img src={info} alt="info" />
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="result-box">
            <div className="result-sub-box">
              <img src={deadwood} alt="" />
            </div>
            <div className="info-box">
              <div className="info-heading">
                <h6>Deadwood</h6>
                <p>No Limit City</p>
              </div>
              <div className="info-icon">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                  }
                >
                  <img src={info} alt="info" />
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="result-box">
            <div className="result-sub-box">
              <img src={deadwood} alt="" />
            </div>
            <div className="info-box">
              <div className="info-heading">
                <h6>Deadwood</h6>
                <p>No Limit City</p>
              </div>
              <div className="info-icon">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                  }
                >
                  <img src={info} alt="info" />
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="result-box">
            <div className="result-sub-box">
              <img src={deadwood} alt="" />
            </div>
            <div className="info-box">
              <div className="info-heading">
                <h6>Deadwood</h6>
                <p>No Limit City</p>
              </div>
              <div className="info-icon">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                  }
                >
                  <img src={info} alt="info" />
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="result-box">
            <div className="result-sub-box">
              <img src={deadwood} alt="" />
            </div>
            <div className="info-box">
              <div className="info-heading">
                <h6>Deadwood</h6>
                <p>No Limit City</p>
              </div>
              <div className="info-icon">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                  }
                >
                  <img src={info} alt="info" />
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="result-box">
            <div className="result-sub-box">
              <img src={deadwood} alt="" />
            </div>
            <div className="info-box">
              <div className="info-heading">
                <h6>Deadwood</h6>
                <p>No Limit City</p>
              </div>
              <div className="info-icon">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                  }
                >
                  <img src={info} alt="info" />
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="result-box">
            <div className="result-sub-box">
              <img src={deadwood} alt="" />
            </div>
            <div className="info-box">
              <div className="info-heading">
                <h6>Deadwood</h6>
                <p>No Limit City</p>
              </div>
              <div className="info-icon">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                  }
                >
                  <img src={info} alt="info" />
                </OverlayTrigger>
              </div>
            </div>
          </div>

          <div className="result-box">
            <div className="result-sub-box">
              <img src={deadwood} alt="" />
            </div>
            <div className="info-box">
              <div className="info-heading">
                <h6>Deadwood</h6>
                <p>No Limit City</p>
              </div>
              <div className="info-icon">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                  }
                >
                  <img src={info} alt="info" />
                </OverlayTrigger>
              </div>
            </div>
          </div>

          <div className="result-box">
            <div className="result-sub-box">
              <img src={deadwood} alt="" />
            </div>
            <div className="info-box">
              <div className="info-heading">
                <h6>Deadwood</h6>
                <p>No Limit City</p>
              </div>
              <div className="info-icon">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                  }
                >
                  <img src={info} alt="info" />
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SlotGrid;
