import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, FormControl, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import closeicon from "../../assets/images/chat/close.svg";
import emoji from "../../assets/images/chat/emoji.svg";
import chaticon from "../../assets/images/chat/chat.png";
import ChatContent from "./chatcontent";
import UserContext from "../../context/UserContext";
import socket from "../../config/socket";
import { userInstance } from "../../config/axios";
import EmojiPopup from "./emojiPopup";
import { getCookie } from '../../utils/cookies';
import chatbot from "../../assets/images/chat/bot.svg";
import "./chat.css";
const Chat = ({ show, setShow }) => {
  const messagesEndRef = useRef(null);
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState();
  // const [menu, setMenu] = useState();
  // const [close, setClose] = useState();
  const [view, setView] = useState(false);
  const [message, setMessage] = useState("");
  const [chatdata, setChatdata] = useState([]);
  const [emojiShow, setEmojiShow] = useState(false);
  const handleShowEmoji = () => {
    setEmojiShow(!emojiShow);
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleChat = (e) => {

    if (e.target.value === "/") {
      setOpen(true);
    } else {
      setOpen(false);
    }
    // if(e.target.value.match(" ")){
    //   alert(" ");
    // }
    // else {
    //  
    // }
    setMessage(e.target.value);
    scrollToBottom();
  };

 

  // const handleChatDropdown = (item) => {
  //    setMenu(item);
  //    setClose(!close);
  // }

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          // setMenu(false);
          // setClose(false);
          setEmojiShow(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleCloseChat = () => {
    scrollToBottom();
    setShow(!show);
  };

  const handleTip = () => {
    setView(!view);
  };

  const handleChatSubmit = (e) => {
    e.preventDefault();
    if(message.trim().length > 0){
    if (!getCookie("token")) {
      toast.error("You are not logged in . Please login to send message.");
    }
    else{
      socket.emit("chatMessageByUser", {
        chatMessage: message,
        userId: getCookie("userid"),
        username: user?.firstName ?? "",
        // avatar: user?.avatar ?? "",
      });
      scrollToBottom();
      // setDummy(true);
      setMessage("");
    }
  }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const alldata = await userInstance.get(`/chatRoomData`, {
          headers: { authorization: `Bearer ${getCookie("token")}` },
        });
        if (isMounted) {
          setChatdata(alldata?.data?.allChatsUser);
        }
      } catch (error) {
      }
    })();

    return () => {
      isMounted = false;
    };
    
  }, []);

  useEffect(() => {
    socket.on("newChatMessage", (data) => {
      setChatdata((prev) => [...prev, data]);
      scrollToBottom();
    });
    return () => {
      socket.off("newChatMessage");
    };
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [messagesEndRef]);

  const handleSelectEmojiComment = (emoji) => {
    setMessage((old) => old + emoji);
  };
  return (
    <div className="chat">
      <span
        onClick={handleCloseChat}
        className="chat-icon"
        role="presentation">
        {!show ? <img src={chaticon} alt="" /> : <img src={closeicon} alt="" /> }
      </span>
      <div className="chat-header">
        <h3><img src={chatbot} alt="" />Live Chat</h3>
      </div>
      <div className="chat-content">
          {chatdata?.map((el, i) => (
            <div key={i}>
              <div className="container1">
                <div className="chat-user chat-admin chat-user">
                  <div className="chat-user-info">
                    <h4>{el?.userId?.firstName}</h4>
                    <div className="user-chat-details">
                      <p>{el?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <span ref={messagesEndRef} />
      </div>
      <div className="chat-footer">
            <Form>
              <FormControl
                placeholder="Type your message..."
                onChange={(e) => handleChat(e)}
                value={message}
                as="textarea"
              />
              <div className="chat-btn">
                <Button
                  type="submit"
                  disabled={message.length === 0}
                  onClick={handleChatSubmit}
                >
                  <i className="las la-paper-plane"></i>
                </Button>
                <Button onClick={handleShowEmoji}>
                  <img src={emoji} alt="" />
                </Button>
              </div>
              {open ? <ChatContent handleTip={handleTip} /> : ""}
            </Form>
      </div>
      <div className="emojiBocx chatemoji" ref={wrapperRef}>
        {emojiShow && (
          <EmojiPopup handleSelectEmoji={handleSelectEmojiComment} />
        )}
      </div>
    </div>
  );
};

export default Chat;
