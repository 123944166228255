import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import logo from "../../assets/images/header/logo.png";
import twitter from "../../assets/images/footer/twitter.svg";
import discord from "../../assets/images/footer/discord.svg";
// import notification from "../../assets/images/header/notification.svg";
import menuicon from "../../assets/images/header/menuicon.svg";
// import searchicon from "../../assets/images/header/search.svg";
import user from "../../assets/images/header/avatar.png";
import activeMenu from "../../assets/images/header/close.svg";
// import searchActive from "../../assets/images/header/search-active.svg";
// import menu1 from "../../assets/images/header/menu1.svg";
// import menu2 from "../../assets/images/header/menu2.svg";
// import menu3 from "../../assets/images/header/menu3.svg";
// import menu4 from "../../assets/images/header/menu4.svg";
// import menu5 from "../../assets/images/header/menu5.svg";
import "./footer.css";

const Footer = ({
  handleSidebar,
  handleChat,
  openSidebar,
  search,
}) => {


  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="footer-top">
            <div className="footer-logo">
              <img src={logo} alt="" />
              <p>
                Winit is a leading gaming casino company that offers an exciting
                and immersive gaming experience to its customers. Founded in
                2023, including a wide range of gaming options such as slot
                machines, table games, poker rooms.
              </p>
              <ul>
                <li>
                  <Link to="">
                    <img src={twitter} alt="twitter" />
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <img src={discord} alt="discord" />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footer-menu">
              <h5>Game</h5>
              <ul>
                <li>
                  <Link to="/">Slot</Link>
                </li>
                <li>
                  <Link to="/limbo">Limbo</Link>
                </li>
                <li>
                  <Link to="/crash">Crash</Link>
                </li>
                <li>
                  <Link to="/plinko">Plinko</Link>
                </li>
                <li>{/* <Link to="/livegames">Live Games</Link> */}</li>
              </ul>
            </div>

            <div className="footer-menu">
              <h5>About</h5>
              <ul>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>{/* <Link to="/">KYC Policy</Link> */}</li>
                <li>
                  <Link to="/">Cookie Policy</Link>
                </li>
                <li>
                  <Link to="/Privacy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/term-and-services">Terms of Service</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright-text text-center">
            <p>Copyright © 2023 WINIT.VIP. All Rights Reserved.</p>
          </div>
        </div>
      </div>
      <div className="sticky-footer">
        <div
          className={`sticky-link menu-box ${openSidebar ? "active-menu" : ""}`}
          onClick={handleSidebar}
        >
          {openSidebar ? (
            <img src={activeMenu} alt="" />
          ) : (
            <img src={menuicon} alt="" />
          )}
        </div>
        {/* <div
          className={`sticky-link search-box ${search ? "active-menu" : ""}`}
        >
          <Link to="/">
            {search ? (
              <img src={searchActive} alt="" />
            ) : (
              <img src={searchicon} alt="" />
            )}
          </Link>
        </div> */}
        {/* <div
          className={`sticky-link ${show ? "active-menu" : ""}`}
          // onClick={handleChat}
          onClick={handleCloseChat}
        >
          {show ? <img src={chatActive} alt="" /> : <img src={chat} alt="" />}
        </div> */}
        <div className="my-profile-menu">
          <div className="p-name">
            <Dropdown align="left">
              <Dropdown.Toggle variant="success" id="dropdown-profile">
                <div className="sticky-link p-pic">
                  <img src={user} alt="" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Link to="/profile">
                  <i className="bi bi-person-circle profile_icon"></i> Profile
                </Link>
                {/* <Link to="/">
                  <img src={menu2} alt="" /> Blog
                </Link>
                <Link to="/">
                  <img src={menu3} alt="" /> Affiliates
                </Link>
                <Link to="/">
                  <img src={menu4} alt="" /> Rewards
                </Link> */}
                <Dropdown.Item>
                  <i className="bi bi-box-arrow-right profile_icon"></i> Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* <div className='notification-icon'>
            <Button>
              <img src={notification} alt='' />
            </Button>
          </div> */}
          </div>
        </div>
        {/* <div className="sticky-link">
          <img src={notification} alt="" />
        </div> */}
      </div>
    </>
  );
};

export default Footer;
