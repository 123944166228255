import { createContext } from "react";
import { getCookie } from "../utils/cookies";

const UserContext = createContext({
  token: getCookie("token") ? getCookie("token") : "",
  user: {},
  setUser: () => {},
  friends: [],
  setFriends: () => {},
  posts: [],
  setPosts: () => {},
  isCheckEmailVerification: false,
  setIsCheckEmailVerification: () => {},
  storeEmailForReverification: "",
  setStoreEmailForReverification: () => {},
  userloader: {},
  setUserloader: () => {},
  loader: false,
  setLoader: () => {},
  openSidebar:false,
  setOpenSidebar:()=>{},
  handleSidebar:()=>{}
});

export default UserContext;
