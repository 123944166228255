import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink} from "react-router-dom";
import Layout from "../layout/layout";
import overview from "../../assets/images/profile/overview.svg";
import roulette from "../../assets/images/profile/roulette.svg";
import dice from "../../assets/images/profile/dice.svg";
import "./verify.css";


const VerifyLayout = ({children}) => {

    return (
        <Layout>
            <div className="profile-wrapper">
                <div className="container">
                    <div className="profile-grid">

                       <div className="profile-sidebar">


                            <div className="profile-menu">
                                <h5>Verify Games</h5>
                                <Nav>
                                    <NavLink to="/overview"><img src={overview} alt="" /> Overview</NavLink>
                                    <NavLink to="/verifyroulette"><img src={roulette} alt="" /> Roulette</NavLink>
                                    <NavLink to="/verifydice"><img src={dice} alt="" /> Dice</NavLink>
                                </Nav>

                            </div>

                        </div>

                        <div className="profile-content">
                            {children}
                        </div>
 

                    </div>

                </div>
            </div>
        </Layout>

    );
};

export default VerifyLayout;