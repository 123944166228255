import axios from "axios";
import { server } from "../config/keys";
import { getCookie } from "../utils/cookies";
export const getToken = () => {
  const tokenData = getCookie("token")
    ? getCookie("token")
    : "";
  return tokenData;
};

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export const userInstance = axios.create({
  baseURL: `${server}/v1/users`,
  headers: { Authorization: getAuthorizationHeader() },
});

export const authInstance = axios.create({
  baseURL: `${server}/v1/auth`,
  headers: {
    Authorization: getAuthorizationHeader(),
  },
});