
import React from 'react';
import { Link } from "react-router-dom";
import logo from "../../assets/images/header/logo.png";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { verifyEmail } from "../../utils/Api";
import UserContext from "../../context/UserContext";
import "./register.css";

const VerifyEmail = () => {
  const [isVerified, setIsVerified] = useState(false);
  const { setToken, setUser } = useContext(UserContext);
  useEffect(() => {
    const handleMailVerify = async (token) => {
      const res = await verifyEmail(token);
      if (res.user && res.tokens) {
        const { refresh } = res.tokens
        if (refresh) {
          localStorage.setItem("refreshToken", refresh.token);
        }
        setToken(res?.tokens);
        setUser(res?.user);
        setIsVerified(true);
      }
    };
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      handleMailVerify(token);
    }
    // eslint-disable-next-line
  }, []);
  const handleLetsPlay = () => {
    window.location.href = "/";
  };
  return (
    <div className="signup">
      <div className="logo-icon">
      </div>
      <div className="container">
        <div className="signup-content">
          {isVerified ? (
            <div className="signup-form email-notification">
              <img src={logo} alt="" />
              <h1>Your email`s confirmed!</h1>
              <span
                className="btn"
                onClick={handleLetsPlay}
                role="presentation"
              >
                Let`s Play!
              </span>
            </div>
          ) : (
            <div className="signup-form email-notification">
              <div className="signup-form-icon">
              </div>
              <h3>Checking...</h3>
              <Link className="lets-play-btn" to="/">
                <Spinner animation="border" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
