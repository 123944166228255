import React from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Layout from "../../layout/layout";
import slot from "../../../assets/images/livegames/slot.svg";
import slotsm from "../../../assets/images/livegames/slotsm.svg";
import searchicon from "../../../assets/images/header/search.svg";
import fire from "../../../assets/images/livegames/fire.svg";
import game1 from "../../../assets/images/home/banner3.jpg";
import game2 from "../../../assets/images/home/banner2.jpg";
import game3 from "../../../assets/images/home/banner4.jpg";
import info from "../../../assets/images/header/info.svg";
import SlotGrid from "./grids";
import Slider from "react-slick";
import "./slot.css";

const SlotGames = () => {
  let history = useHistory();
  const handleSlotPage = () => {
    history.push("/");
  };
  return (
    <Layout>
      <div className="livegames-page">
        <div className="games-header">
          <div className="games-head">
            <div className="games-heading">
              <h2>
                <img src={slot} alt=""></img>Slots
              </h2>
            </div>
            <div className="games-search-box">
              <Form>
                <div className="">
                  <Form.Group>
                    <div className="ref-input input-img">
                      <img src={searchicon} alt="" />
                      <Form.Control
                        type="text"
                        placeholder="Search for a game..."
                      />
                      <div className="ref-input-btn">
                        <Button>SEARCH</Button>
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="games-body">
            <div className="top-games">
              <div className="top-games-heading">
                <h2>
                  <img src={fire} alt=""></img>Top Slots
                </h2>
              </div>
            </div>

            <SlotSlider handleSlotPage={handleSlotPage} />

            <div className="all-live-games">
              <SlotGrid title="All Slots" titleImg={slotsm} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SlotGames;

const SlotSlider = ({ handleSlotPage }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "150px",
  };

  return (
    <div className="slot-slider">
      <div className="container">
        <div className="slot-slider-content">
          <Slider {...settings}>
            <div className="game-rbox" onClick={handleSlotPage}>
              <div className="game-result-box">
                <div className="game-result-sub-box">
                  <img src={game1} alt="game" />
                </div>
                <div className="info-box">
                  <div className="info-heading">
                    <h6>Sweet Bonanza</h6>
                    <p>Pragmatic Play</p>
                  </div>
                  <div className="info-icon">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                      }
                    >
                      <img src={info} alt="info" />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
            <div className="game-rbox" onClick={handleSlotPage}>
              <div className="game-result-box">
                <div className="game-result-sub-box">
                  <img src={game2} alt="game" />
                </div>
                <div className="info-box">
                  <div className="info-heading">
                    <h6>Sweet Bonanza XMAS</h6>
                    <p>Pragmatic Play</p>
                  </div>
                  <div className="info-icon">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                      }
                    >
                      <img src={info} alt="info" />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
            <div className="game-rbox" onClick={handleSlotPage}>
              <div className="game-result-box">
                <div className="game-result-sub-box">
                  <img src={game3} alt="game" />
                </div>
                <div className="info-box">
                  <div className="info-heading">
                    <h6>Gates of Olympus</h6>
                    <p>Pragmatic Play</p>
                  </div>
                  <div className="info-icon">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                      }
                    >
                      <img src={info} alt="info" />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>

            <div className="game-rbox" onClick={handleSlotPage}>
              <div className="game-result-box">
                <div className="game-result-sub-box">
                  <img src={game1} alt="game" />
                </div>
                <div className="info-box">
                  <div className="info-heading">
                    <h6>Sweet Bonanza</h6>
                    <p>Pragmatic Play</p>
                  </div>
                  <div className="info-icon">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                      }
                    >
                      <img src={info} alt="info" />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>

            <div className="game-rbox" onClick={handleSlotPage}>
              <div className="game-result-box">
                <div className="game-result-sub-box">
                  <img src={game1} alt="game" />
                </div>
                <div className="info-box">
                  <div className="info-heading">
                    <h6>Sweet Bonanza</h6>
                    <p>Pragmatic Play</p>
                  </div>
                  <div className="info-icon">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                      }
                    >
                      <img src={info} alt="info" />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
            <div className="game-rbox" onClick={handleSlotPage}>
              <div className="game-result-box">
                <div className="game-result-sub-box">
                  <img src={game1} alt="game" />
                </div>
                <div className="info-box">
                  <div className="info-heading">
                    <h6>Sweet Bonanza</h6>
                    <p>Pragmatic Play</p>
                  </div>
                  <div className="info-icon">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip">House Edge: 3.50%</Tooltip>
                      }
                    >
                      <img src={info} alt="info" />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};
