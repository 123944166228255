import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import { Form, Button } from "react-bootstrap";
import coin from "../../../assets/images/header/gem.png";
import user from "../../../assets/images/chat/user.png";
import user1 from "../../../assets/images/chat/user1.png";
import user2 from "../../../assets/images/chat/user2.png";
import red from "../../../assets/images/game/red-coin.png";
import green from "../../../assets/images/game/green-coin.png";
import blue from "../../../assets/images/game/blue-coin.png";
import { ToastContainer } from "react-toastify";
// import arrow from "../../../assets/images/home/Dropdown.svg";
import "react-toastify/dist/ReactToastify.css";
import "./roulette.css";

const Roulette = () => {
  const [spinvalue, setSpinValue] = useState(0);
  const [spintime, setSpinTime] = useState(0);
  const [openList1] = useState();
  const [openList2] = useState();
  const [openList3] = useState();

  const [bet, setBet] = useState(null);

  const [betOnPurple, setBetOnPurple] = useState(false);
  const [betOnGreen, setBetOnGreen] = useState(false);
  const [betOnRed, setBetOnRed] = useState(false);

  const updateBetSizeAmount = (event) => {
    var inputValue = parseFloat(event.target.value);
    if (inputValue > 1000000 || inputValue < 0)
      return;

    var roundedBetSize = Math.round((inputValue + Number.EPSILON) * 100) / 100;
    setBet(roundedBetSize);
  }

  const handlePlaceBet = (betOn) => {

    if (betOn === "purple") {
      if (betOnPurple)
        setBetOnPurple(false);
      else if (betOnGreen && betOnRed)
        return;
      else
        setBetOnPurple(true);
    }
    
    else if (betOn === "red") {
      if (betOnRed)
        setBetOnRed(false);
      else if (betOnGreen && betOnPurple)
        return;
      else
        setBetOnRed(true);
    }
      
    else if (betOn === "green") {
      if (betOnGreen)
        setBetOnGreen(false);
      else if (betOnRed && betOnPurple)
        return;
      else
        setBetOnGreen(true);
    }
      
  };

  const [seconds, setSeconds] = useState(20);

  // const handleList1 = () => {
  //   setOpenList1(!openList1);
  // };
  // const handleList2 = () => {
  //   setOpenList2(!openList2);
  // };
  // const handleList3 = () => {
  //   setOpenList3(!openList3);
  // };

  const updateBetSize = (updateType) => {
    var newBetSize = 0;

    if (updateType === "+0.01")
      newBetSize = bet + 0.01;
    else if (updateType === "+0.1")
      newBetSize = bet + 0.1;
    else if (updateType === "+1")
      newBetSize = bet + 1;
    else if (updateType === "+10")
      newBetSize = bet + 10;
    else if (updateType === "+100")
      newBetSize = bet + 100;
    else if (updateType === "/2")
      newBetSize = bet / 2;
    else if (updateType === "X2")
      newBetSize = bet * 2;
    else if (updateType === "Clear")
      newBetSize = 0;

    var roundedBetSize = Math.round((newBetSize + Number.EPSILON) * 100) / 100;
    if (roundedBetSize> 100000)
      return;

    setBet(roundedBetSize);
  }

  // const handlespinvalue = () =>{
  //     setSpinValue(10000)
  //     setSpinTime(2)
  //     setTimeout(() => {
  //         setSpinTime(0)
  //         setTimeout(() => {
  //             setSpinValue(0)
  //         }, 100);
  //     }, 2000);
  // }

  useEffect(() => {
    const interval = setInterval(() => {
      setSpinValue(10000);
      setSpinTime(2);
      setTimeout(() => {
        setSpinTime(0);
        setTimeout(() => {
          setSpinValue(0);
        }, 100);
      }, 2000);
    }, 21000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let interval = null;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      interval = setInterval(() => {
        setSeconds(20 - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  // const notify = () =>
  //   toast.success("Deposit of $458.50 Confirmed", {
  //     position: "bottom-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //   });

  // const notifyFailed = () =>
  //   toast.error("Deposit of $824.52 Failed", {
  //     position: "bottom-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //   });

  return (
    <Layout>
      <ToastContainer />

      <div className="roulette-wrapper">
        <div className="container">
          <div className="previous-result-content">

            <div className="previous-game">
              <div className="prevoius-content">
                <p style={{textAlign: 'left'}}>PREVIOUS 10 ROLLS</p>
              </div>
              <div className="prevoius-gmae-list">
                <div className={`previous-rolls-item coin-red`}>
                  <img src={red} alt="" />
                </div>
                <div className={`previous-rolls-item coin-green`}>
                  <img src={green} alt="" />
                </div>
                <div className={`previous-rolls-item coin-blue`}>
                  <img src={blue} alt="" />
                </div>
                <div className={`previous-rolls-item coin-red`}>
                  <img src={red} alt="" />
                </div>
                <div className={`previous-rolls-item coin-green`}>
                  <img src={green} alt="" />
                </div>
                <div className={`previous-rolls-item coin-blue`}>
                  <img src={blue} alt="" />
                </div>
                <div className={`previous-rolls-item coin-red`}>
                  <img src={red} alt="" />
                </div>
                <div className={`previous-rolls-item coin-green`}>
                  <img src={green} alt="" />
                </div>
                <div className={`previous-rolls-item coin-blue`}>
                  <img src={blue} alt="" />
                </div>
                <div className={`previous-rolls-item coin-blue`}>
                  <img src={blue} alt="" />
                </div>
              </div>
            </div>

            <div className="last-game">
              <div className="last-label">Last 100</div>
              <div className="last-item coin-red">
                <img src={red} alt="" />
              </div>
              <div className="label-coin">20</div>
              <div className="last-item coin-green">
                <img src={green} alt="" />
              </div>
              <div className="label-coin">30</div>
              <div className="last-item coin-blue">
                <img src={blue} alt="" />
              </div>
              <div className="label-coin">50</div>
            </div>
          </div>

          <div className="roulette_section">
            <div
              className={`roulette_container ${
                spinvalue > 0 ? `` : `disable-roulette`
              } `}
            >
              {/* <div className="roulette_marker"></div> */}

              {/* {spinvalue === 0 &&
                        <div className="rolling">
                            <span className="wow animate__animated animate__bounceIn animate__infinite animate__slow">ROLLING</span>
                            <h2 className="wow animate__animated animate__slideInUp">{seconds}<span>s</span></h2>
                        </div>
                        } */}

              <div className="roulette-container-shadow--left"></div>

              <div className="roulette-indicator roulette-indicator--top">
                <span></span>
              </div>

              <div className="roulette_wrapper">
                <div
                  className="roulette"
                  style={{
                    backgroundPosition: `${-spinvalue}px center`,
                    transitionDuration: `${spintime}s`,
                  }}
                ></div>
              </div>

              <div className="roulette-container-shadow--right"></div>
            </div>
          </div>

          <div className="predifined-bet">
            <div className="credit-container">
              <div className="credit-amount">
                <div className="credit-input wow animate__animated animate__slideInUp">
                  <Form>
                    <img src={coin} alt="coin" />
                    <Form.Control
                      type="number"
                      placeholder="Enter Bet Amount"
                      name="amount"
                      autoComplete="off"
                      value={bet}
                      onChange={updateBetSizeAmount}
                    />
                  </Form>
                </div>
              </div>

              <div className="get-credit-box">
                <div
                  className="credit-box wow animate__animated animate__slideInUp"
                  onClick={() => updateBetSize("+0.01")}
                >
                  <input type="radio" name="credit" />
                  <div className="credit-pay">
                    <h5>+0.01</h5>
                  </div>
                </div>

                <div
                  className="credit-box wow animate__animated animate__slideInUp"
                  onClick={() => updateBetSize("+0.1")}
                >
                  <input type="radio" name="credit" />
                  <div className="credit-pay">
                    <h5>+0.1</h5>
                  </div>
                </div>

                <div 
                  className="credit-box wow animate__animated animate__slideInUp"
                  onClick={() => updateBetSize("+1")}
                >
                  <input type="radio" name="credit" />
                  <div className="credit-pay">
                    <h5>+1</h5>
                  </div>
                </div>

                <div 
                  className="credit-box wow animate__animated animate__slideInUp"
                  onClick={() => updateBetSize("+10")}
                >
                  <input type="radio" name="credit" />
                  <div className="credit-pay">
                    <h5>+10</h5>
                  </div>
                </div>

                <div 
                  className="credit-box wow animate__animated animate__slideInUp"
                  onClick={() => updateBetSize("+100")}
                >
                  <input type="radio" name="credit" />
                  <div className="credit-pay">
                    <h5>+100</h5>
                  </div>
                </div>

                <div 
                  className="credit-box wow animate__animated animate__slideInUp"
                  onClick={() => updateBetSize("/2")}
                >
                  <input type="radio" name="credit" />
                  <div className="credit-pay">
                    <h5>/2</h5>
                  </div>
                </div>

                <div 
                  className="credit-box wow animate__animated animate__slideInUp"
                  onClick={() => updateBetSize("X2")}
                >
                  <input type="radio" name="credit" />
                  <div className="credit-pay">
                    <h5>X2</h5>
                  </div>
                </div>

                <div className="credit-box wow animate__animated animate__slideInUp">
                  <input type="radio" name="credit" />
                  <div className="credit-pay">
                    <h5>Max</h5>
                  </div>
                </div>

                <div 
                  className="credit-box wow animate__animated animate__slideInUp"
                  onClick={() => updateBetSize("Clear")}
                >
                  <input type="radio" name="credit" />
                  <div className="credit-pay">
                    <h5>Clear</h5>
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* 
                <div className="spin-button wow animate__animated animate__slideInUp">
                    <Button className="l-btn" onClick={handlespinvalue} disabled={spinvalue > 0}>Spin</Button>
                </div> */}

          <div className="place-bet-container">
            <div
              className={`place-bet-box one-third wow animate__animated animate__slideInUp ${openList1 ? 'open-listing' : 'hide-listing'} ${
                bet === "2X" ? "active" : ""
              }`}
            >
              <div className="bet-header blue-head">
                <Button onClick={() => handlePlaceBet("purple")}>
                  <h4>
                    <img src={blue} alt="" /> <span>{betOnPurple ? "BET PLACED" : "PLACE BET"}</span>
                  </h4>
                  <span className="place">
                    WIN 2X
                  </span>
                </Button>
              </div>

              {/*<div className="bet-list bet-list-winner">
                <ul>
                  <li>
                    <div className="bet-user">
                      <img src={user} alt="coin" />
                      <div className="bet-user-name">
                        <div className="bet-user-section">
                          <h5>Austistic Panda</h5>
                          <span className="yellow-badge">89</span>
                        </div>
                        <div className="bet-user-amount">
                          <img src={coin} alt="coin" />
                          140.00
                        </div>
                      </div>
                    </div>
                    <div className="bet-user-amount">
                      140.00 Bonus
                      <span className="mob-toggle-icon" onClick={() => handleList1()}>
                        <img src={arrow} alt="arrow" />{" "}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            */}

              <BetContent classes="gray" />
            </div>

            <div
              className={`place-bet-box one-third wow animate__animated animate__slideInUp ${openList2 ? 'open-listing' : 'hide-listing'} ${
                bet === "14X" ? "active" : ""
              }`}
            >
              <div className="bet-header red-head">
                <Button onClick={() => handlePlaceBet("red")}>
                  <h4>
                    <img src={red} alt="" /> <span>{betOnRed ? "BET PLACED" : "PLACE BET"}</span>
                  </h4>
                  <span className="place">
                    WIN 14X
                  </span>
                </Button>
              </div>

              {/*<div className="bet-list bet-list-winner">
                <ul>
                  <li>
                    <div className="bet-user">
                      <img src={user} alt="coin" />
                      <div className="bet-user-name">
                        <div className="bet-user-section">
                          <h5>Austistic Panda</h5>
                          <span className="yellow-badge">89</span>
                        </div>
                        <div className="bet-user-amount">
                          <img src={coin} alt="coin" />
                          140.00
                        </div>
                      </div>
                    </div>
                    <div className="bet-user-amount">
                      140.00 Bonus
                      <span className="mob-toggle-icon" onClick={() => handleList2()}>
                        <img src={arrow} alt="arrow" />{" "}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>*/}
              <BetContent classes="green" />
            </div>

            <div
              className={`place-bet-box one-third wow animate__animated animate__slideInUp ${openList3 ? 'open-listing' : 'hide-listing'} ${
                bet === "2X1" ? "active" : ""
              }`}
            >
              <div className="bet-header green-head">
                <Button onClick={() => handlePlaceBet("green")}>
                  <h4>
                    <img src={green} alt="" /> <span>{betOnGreen ? "BET PLACED" : "PLACE BET"}</span>
                  </h4>
                  <span className="place">
                    WIN 2X
                  </span>
                </Button>
              </div>

              {/*<div className="bet-list bet-list-winner">
                <ul>
                  <li>
                    <div className="bet-user">
                      <img src={user} alt="coin" />
                      <div className="bet-user-name">
                        <div className="bet-user-section">
                          <h5>Austistic Panda</h5>
                          <span className="yellow-badge">89</span>
                        </div>
                        <div className="bet-user-amount">
                          <img src={coin} alt="coin" />
                          140.00
                        </div>
                      </div>
                    </div>
                    <div className="bet-user-amount">
                      140.00 Bonus
                      <span className="mob-toggle-icon" onClick={() => handleList3()}>
                        <img src={arrow} alt="arrow" />{" "}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>*/}

              <BetContent classes="cream" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Roulette;

const BetContent = ({ classes }) => {
  return (
    <div className="bet-list">
      <div className="bet-amount">
        <span className={`${classes}`}>52 Betting</span>
        <span>
          {" "}
          <img src={coin} alt="coin" /> 385.96
        </span>
      </div>
      <ul>
        {["1", "2", "3", "4", "5"].map((el) => {
          return ["1", "2", "3", "4", "5"].map((item) => {
            return (
              <>
                <li key={item + el}>
                  <div className="bet-user">
                    <img src={user} alt="coin" />
                    <span className="yellow-badge">89</span>
                    <h5>Dayhzz</h5>
                  </div>
                  <div className="bet-user-amount">
                    <img src={coin} alt="coin" /> 140.00
                  </div>
                </li>
                <li>
                  <div className="bet-user">
                    <img src={user1} alt="coin" />
                    <span className="pink-badge">72</span>
                    <h5>Juiced 0</h5>
                  </div>
                  <div className="bet-user-amount">
                    <img src={coin} alt="coin" /> 98.00
                  </div>
                </li>
                <li>
                  <div className="bet-user">
                    <img src={user2} alt="coin" />
                    <span className="blue-badge">65</span>
                    <h5>Aced Twice</h5>
                  </div>
                  <div className="bet-user-amount">
                    <img src={coin} alt="coin" /> 69.42
                  </div>
                </li>
              </>
            );
          });
        })}
      </ul>
    </div>
  );
};
