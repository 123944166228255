import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { ResetPasswordCheckSchema } from "../../validationSchema/authSchema";
import { useForm } from "react-hook-form";
import { authInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { useState } from "react";

const Reset = ({ handleCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    // reset,
    // setValue,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(ResetPasswordCheckSchema),
  });

  const [pass, setPass] = useState("password");
  const handlePass = () => {
    setPass(!pass);
  };

  const handleResetPassword = async (values) => {
    setLoading(true);
    try {
      const res = await authInstance.post(
        "/reset-password",
        { password: values.password },
        {
          params: {
            token: window.location.search
              .split("?reset-password-token=")
              .join(""),
          },
        }
      );

      if (res?.statusText === "No Content") {
        handleCloseModal();
        toast.success("Your password has been successfully updated!");
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      }
    } catch (error) {
      toast.error("Link has been expired");
      window.location.href = "/";
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className='forget-password-section'>
      <div className='forget-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='forget-form'>
                <h1>
                  Reset your <span>Password</span>
                </h1>
                <p>Enter your new password</p>
                <div className='forget-box'>
                  <Form onSubmit={handleSubmit(handleResetPassword)}>
                    <Form.Group controlId='formBasicloginone'>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={pass ? "password" : "text"}
                        placeholder='Email Password'
                        name='password'
                        autoComplete='off'
                        {...register("password", {
                          required: "Enter Valid Password",
                        })}
                      />
                      <span className='input-icon1' onClick={handlePass}>
                        <i className={pass ? "las la-eye" : "las la-eye-slash"}></i>
                      </span>
                      {errors.password &&
                        (errors.password.message !== "Password is invalid." ? (
                          <p className='error-msg'>{errors.password.message}</p>
                        ) : (
                          <p className='error-msg'>
                            {errors.password.message}
                            <button
                              type='button'
                              className='btn btn-secondary bg-none tooltip-pswd '
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              title='• must contain both upper and lower case characters
• must contain numeric characters
• must contain special characters
• must contain 8 characters
'
                            >
                              <span className='input-icon1'>
                                <i className='bi bi-info-circle'></i>
                              </span>
                            </button>
                          </p>
                        ))}
                    </Form.Group>
                    <Form.Group controlId='formBasicloginone'>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type={pass ? "password" : "text"}
                        placeholder='Confirm Password'
                        name='verifyPassword'
                        {...register("verifyPassword", {
                          required: "Enter Valid Confirm Password",
                        })}
                      />
                      <span className='input-icon1' onClick={handlePass}>
                        <i className={pass ? "las la-eye" : "las la-eye-slash"}></i>
                      </span>
                      {errors.verifyPassword && (
                        <p className='error-msg'>
                          {errors.verifyPassword.message}
                        </p>
                      )}
                    </Form.Group>
                    <div className='login-button'>
                      <Button type='submit' className='l-btn'>
                        {loading ? (
                          <Spinner
                            animation='border'
                            size='sm'
                            className='accept-btn-show-btn'
                          />
                        ) : (
                          "Change Password"
                        )}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
