import React from "react";
import Layout from "../layout/layout";
import "./privacy.css";

const Privacy = () => {
  return (
    <Layout>
      <div className="privacy-page">
        <h1 className="heading">Privacy Policy</h1>
        <p>
          This is the privacy policy of Medium Rare N.V. (owner and operator of
          Winit.) This Privacy Policy sets out how Winit.vip will manage your
          personal information which we collect as a result of your membership
          of Winit, your use of our websites located at https://winit.vip and
          any pages or websites under the 'Winit' brand including any mobile
          phone or tablet applications that are owned and/or operated by us or
          whenever we otherwise deal with you. We are committed to ensuring that
          the personal information we collect about you is protected and is
          used, stored and disclosed in accordance with the Privacy Act and this
          Privacy Policy.
        </p>
        <h1>1. Website Use</h1>
        <p>
          In using the website, you agree to be bound by the terms of this
          Privacy Policy. Winit.vip may review the Privacy Policy periodically
          and reserves the right to change the Privacy Policy at any time at its
          discretion by posting such changes on the website. Changes will be
          effective immediately when posted on the website. Your continued use
          of the website following posting will constitute your acceptance of
          those changes. This Privacy Policy will not apply to websites which
          are linked to the website and over which Winit.vip has no control.
        </p>
        <h1>2. Personal Information</h1>
        <p>
          The kinds of Personal Information that Winit.vip may collect from you
          is:
        </p>
        <ul>
          <li>Name</li>
          <li>Email Address</li>
          <li>Personally Submitted Preferences</li>
          <li>Date of Birth</li>
          <li>Country of Citizenship</li>
          <li>Physical Address</li>
          <li> Identification Number</li>
          <li>Government Issued Identification</li>
          <li>Location Data</li>
          <li> Device Information</li>
          <li>IP Address</li>
        </ul>
        <p>
          The Company collects, or may collect, such information through methods
          including:
        </p>
        <ul>
          <li>Data input during account registration processes</li>
          <li>Communications with Customers</li>
        </ul>
        <h1>3. Collection Purposes</h1>
        <p>
          Winit.vip will collect your Personal Information only by lawful and
          fair means and not in an intrusive way to operate its business as a
          licensed online wagering bookmaker for the following purposes:
        </p>
        <ul>
          <li>For know-your-customer (KYC) identity verification.</li>
          <li>
            If you wish to subscribe to Winit.vip's marketing communications.
          </li>
          <li>
            To process your application to become a Member of Winit.vip or any
            managed subdomains.
          </li>
          <li>To provide and improve services to you as a Member.</li>
          <li>
            To identify you as a Member and authenticate your identity for
            security purposes and to comply with our legal obligations.
          </li>
          <li>To maintain your Member account.</li>
          <li>
            To upgrade and enhance your experience within the website or over
            the telephone, or to tailor or develop information, services or
            products to suit your needs which may include market research and
            conducting promotions.
          </li>
          <li>
            To tell you about our products or services that we think may be of
            interest to you by communicating with you via email, SMS or
            telephone.
          </li>
          <li>
            To create aggregate data about Members through demographic
            profiling, statistical analysis of the database to provide to
            potential and existing Winitholders, and to allow for more efficient
            operation of Winit.vip’s business.
          </li>
          <li>To respond to your questions, comments, or requests.</li>
          <li>
            To determine Members' liability to pay goods and services tax and
            other taxes where applicable.
          </li>
          <li>
            To comply with Winit.vip’s contractual, legal, and statutory
            obligations.
          </li>
          <li>
            For taking appropriate action if Winit.vip has reason to suspect
            that unlawful activity or misconduct of a serious nature has been,
            is being or may be engaged in that relates to our functions and
            activities.
          </li>
          <li>To establish, exercise or defend any legal claims.</li>
          <li>To manage job applications.</li>
        </ul>
        <p>
          If you do not provide Winit.vip with the Personal Information we
          request, we may be unable to process your application to become a
          Member, provide you with our services or respond to your enquiry or
          complaint. By using Winit.vip, becoming a Member or otherwise choosing
          to provide Winit.vip with Personal Information you consent to
          Winit.vip collecting, using, processing and potentially disclosing
          your Personal Information for the above purposes and on the bases
          described in this Privacy Policy.
        </p>
        <h1>4. Direct Marketing and Opting Out</h1>
        <p>
          From time to time we may use your Personal Information to inform you
          about our products or services or about promotional activities which
          Winit.vip believes may be of interest or of benefit to you. We may do
          this via email, SMS, telephone or mail. If you no longer wish to
          receive marketing or promotional material from Winit.vip at all or in
          any particular form, you may contact us at any time by email to
          support@winit.vip with your request with which we will comply as soon
          as is practical.
        </p>
        <p>
          From time to time we may contact you in relation to the management and
          administration of your Winit.vip account. These communications can be
          via any of the modes of contact recorded when registering as a Member.
          Such communication does not affect your opt-in or opt-out status for
          direct marketing communications.
        </p>
        <h1>5. Management of your Personal Information</h1>
        <p>
          Winit.vip will take all reasonable steps to ensure that the Personal
          Information which it collects, uses or discloses is correct and is
          stored in a secure environment which is accessed only by authorised
          persons. We store the personal information we receive as described in
          this Privacy Policy for as long as you use our Services or as
          necessary to fulfill the purpose(s) for which it was collected,
          provide our Services, resolve disputes, establish legal defenses,
          conduct audits, pursue legitimate business purposes, enforce our
          agreements, and comply with applicable laws. Winit.vip will destroy or
          permanently de-identify the Personal Information we hold when it is no
          longer required for any purpose permitted under the APPs including our
          legal or operational obligations.
        </p>
        <h1>6. Security of Personal Information</h1>
        <p>
          You acknowledge that no data transmission over the Internet is totally
          secure. Accordingly, Winit.vip does not guarantee or warrant the
          security of any information which you transmit to it. Any information
          which you transmit to Winit.vip is transmitted at your own risk.
          However, once Winit.vip receives your transmission, Winit.vip will
          take reasonable steps to protect your Personal Information from
          misuse, loss and unauthorized access, modification and disclosure
          including by using password protected systems and databases and Secure
          Socket Layer (SSL) technology. By using our Services or providing
          personal information to us, you agree that we may communicate with you
          electronically regarding security, privacy, and administrative issues
          relating to your use of the Services.
        </p>
        <p>
          Winit.vip’s employees, agents and contractors are required to maintain
          the confidentiality of Members' Personal Information and trading
          behavior. Information posted on bulletin boards or communicated within
          a social media environment (for example, Facebook, Twitter, Chat
          Rooms) becomes public information. Winit.vip cannot guarantee the
          security of this type of disclosed information.
        </p>
        <p>
          We take seriously the responsibility to exclude children from access
          to our services. We will not accept their information for the purposes
          of opening a wagering account. It is however, ultimately the
          responsibility of parents or guardians to monitor their children’s
          Internet activities including where appropriate by using Internet
          screening software.
        </p>
        <p>
          Remember to always log out of your account when you have completed
          your time on the website. This is particularly important if you share
          a computer with another person. You are responsible for the security
          of and access to your own computer, mobile device or any other handset
          used to access the website.
        </p>
        <p>
          Ultimately, you are solely responsible for maintaining the secrecy of
          your username, password and any account information. Please be careful
          whenever using the Internet and our website.
        </p>
        <h1>7. Access to Personal Information</h1>
        <p>
          You may access the Personal Information collected by Winit.vip by
          following the “Settings” link on the website or by contacting us on
          support@winit.vip . We will give you access to your Personal
          Information in the manner requested if that is possible and within a
          reasonable period. If we refuse your request or cannot give you access
          in the manner you have requested, we will do what we can to meet your
          requirements by other means. We may not be required to give you access
          to your Personal Information in certain circumstances which are set
          out in the APPs including where it may have an unreasonable impact on
          another individual's privacy. If we refuse access for such reasons, we
          will advise you in writing of the refusal and our reasons and the
          complaint mechanisms available to you.
        </p>
        <h1>8. Delete Personal Data</h1>
        <p>
          You can request to have your personal data deleted if Winit.vip no
          longer have a legal reason to continue to process or store it. Please
          note that this right is not guaranteed - in the sense that Winit.vip
          do not have the ability to comply with your request if it is subject
          to a legal obligation to store your data. You can request the deletion
          of your personal data by sending an email to support@winit.vip
        </p>
        .<h1>9. Contact Details</h1>
        <p>
          If you have any queries, requests for access or correction or
          complaints relating to the handling of your personal information,
          please contact us by email at support@winit.vip or by the site
          customer support chat function.
        </p>
        <h1>10. International Data Transfers</h1>
        <p>
          All information processed by us may be transferred, processed, and
          stored anywhere in the world, including but not limited to other
          countries, which may have data protection laws that are different from
          the laws where you live. We endeavor to safeguard your information
          consistent with the requirements of applicable laws.
        </p>
        <h1> 11. Legal Basis for Processing</h1>
        <p>
          For purposes of the EU General Data Protection Regulation, Winit.vip’s
          processing of your personal information is supported by the following
          lawful bases:
        </p>
        <ul>
          <li>
            Performance of a Contract: Winit.vip needs to process your personal
            information to perform our contract with you or our Customer.
          </li>
          <li>
            Legitimate Interest: Winit.vip processes your personal information
            to further our legitimate interests but only where our interests are
            not overridden by your interests or fundamental rights and freedoms.
          </li>
          <li>
            Consent: In some cases, Winit.vip also relies on your consent to
            process your personal information.
          </li>
          <li>
            Compliance with Legal Obligations: Winit.vip may process your
            personal information to comply with our legal obligations.
          </li>
        </ul>{" "}
        <h1> 12. Supervisor Authority </h1>
        <p>
          If you are located in the European Economic Area, you have the right
          to lodge a complaint with a supervisory authority if you believe our
          processing of your personal information violates applicable law.
        </p>
        <h1> 13. Updates to this Privacy Policy</h1>
        <p>
          As noted, Winit.vip may review, change and update this Privacy Policy
          from time to time reflect our current practices and obligations. We
          will publish our current Privacy Policy on our website at
          https://winit.vip and the changes will take effect at the time of
          publishing. You should review this privacy policy regularly and remain
          familiar with its terms.
        </p>
      </div>
    </Layout>
  );
};

export default Privacy;
